import {AxiosResponse} from "axios";
import {IDataResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {SelectModel} from "../../types/SelectModel";
import {IListTransportLineItem} from "../../interfaces/shared/IListTransportLineItem";
import {IGridResultResponse} from "../../interfaces/response/IGridResultResponse";
import {ISelectTreeModel} from "../../interfaces/shared/ISelectTreeModel";
import {IChatServiceProvider} from "../../interfaces/chat/IChatServiceProvider";
import { IGetAtmospherePrefilledValues } from "../../interfaces/dataHelper/IGetAtmospherePrefilledValues";

export const useGetAccessFilterForwarders = (): {
    isLoading: boolean,
    cancel: () => void,
    getAccessFilterForwarders: () => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAccessFilterForwarders`
    });

    const getAccessFilterForwarders = useCallback(async () => await execute(), [execute]);

    return {getAccessFilterForwarders, isLoading, cancel};
};

export const useGetAccessFilterBranches = (): {
    isLoading: boolean,
    cancel: () => void,
    getAccessFilterBranches: () => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAccessFilterBranches`
    });

    const getAccessFilterBranches = useCallback(async () => await execute(), [execute]);

    return {getAccessFilterBranches, isLoading, cancel};
};

export const useGetAccessFilterDepartments = (): {
    isLoading: boolean,
    cancel: () => void,
    getAccessFilterDepartments: () => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAccessFilterDepartments`
    });

    const getAccessFilterDepartments = useCallback(async () => await execute(), [execute]);

    return {getAccessFilterDepartments, isLoading, cancel};
};

export const useGetAccessFilterTransportLines = (): {
    isLoading: boolean,
    cancel: () => void,
    getAccessFilterTransportLines: (distinctBy: 'source' | 'destination') => Promise<AxiosResponse<IDataResponse<Array<IListTransportLineItem>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<IListTransportLineItem>>>({
        method: 'get'
    });

    const getAccessFilterTransportLines = useCallback(async (distinctBy: 'source' | 'destination') => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAccessFilterTransportLines?distinctBy=${distinctBy}`
    }), [execute]);

    return {getAccessFilterTransportLines, isLoading, cancel};
};

export const useGetAccessFilterServiceProviders = (): {
    isLoading: boolean,
    cancel: () => void,
    getAccessFilterServiceProviders: (name: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getAccessFilterServiceProviders = useCallback(async (name: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAccessFilterServiceProviders?filter=${name}`
    }), [execute]);

    return {getAccessFilterServiceProviders, isLoading, cancel};
};

export const useGetRegionsTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getRegionsTree: (filter: string | null, skip?: number, take?: number) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getRegionsTree = useCallback(async (filter: string | null, skip?: number, take?: number) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getRegionsTree`;

        if (filter && filter !== '') {
            q += `?filter=${filter}`;

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        } else {
            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `?skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getRegionsTree, isLoading, cancel};
}

export const useGetSuppliersTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getSuppliersTree: (filter: string | null, skip?: number, take?: number) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getSuppliersTree = useCallback(async (filter: string | null, skip?: number, take?: number) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getSuppliersTree`;

        if (filter && filter !== '') {
            q += `?filter=${encodeURIComponent(filter)}`;

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        } else {
            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `?skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getSuppliersTree, isLoading, cancel};
}

export const useGetEquipmentsTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getEquipmentsTree: (filter: string | null, skip?: number, take?: number) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getEquipmentsTree = useCallback(async (filter: string | null, skip?: number, take?: number) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getEquipmentsTree`;

        if (filter && filter !== '') {
            q += `?filter=${filter}`;

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        } else {
            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `?skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getEquipmentsTree, isLoading, cancel};
}

export const useGetServiceProvidersTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProvidersTree: (filter: string | null, skip?: number, take?: number) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getServiceProvidersTree = useCallback(async (filter: string | null, skip?: number, take?: number) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getServiceProvidersTree`;

        if (filter && filter !== '') {
            q += `?filter=${encodeURIComponent(filter)}`;

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        } else {
            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `?skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getServiceProvidersTree, isLoading, cancel};
}

export const useGetUserServiceProviderList = (): {
    isLoading: boolean,
    cancel: () => void,
    getUserServiceProviderList: () => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getUserServiceProviderList = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUserServiceProviderList`
    }), [execute]);

    return {getUserServiceProviderList, isLoading, cancel};
};

export const useGetUserServiceProviderListAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getUserServiceProviderListAnonymous: () => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getUserServiceProviderListAnonymous = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUserServiceProviderListAnonymous`
    }), [execute]);

    return {getUserServiceProviderListAnonymous, isLoading, cancel};
};

export const useGetReleaseServiceProviderList = (): {
    isLoading: boolean,
    cancel: () => void,
    getReleaseServiceProviderList: (serviceProviderId: string | null) => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getReleaseServiceProviderList = useCallback(async (serviceProviderId: string | null) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getReleaseServiceProviderList?serviceProviderId=${serviceProviderId}`
    }), [execute]);

    return {getReleaseServiceProviderList, isLoading, cancel};
};

export const useGetContractServiceProviderList = (): {
    isLoading: boolean,
    cancel: () => void,
    getContractServiceProviderList: (orderReleaseGid: string) => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getContractServiceProviderList = useCallback(async (orderReleaseGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getContractServiceProviderList?orderReleaseGid=${orderReleaseGid}`
    }), [execute]);

    return {getContractServiceProviderList, isLoading, cancel};
};

export const useGetUsersLoginsForAssigment = (): {
    isLoading: boolean,
    cancel: () => void,
    getUsersLoginsForAssigment: () => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getUsersLoginsForAssigment = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUsersLoginsForAssigment`
    }), [execute]);

    return {getUsersLoginsForAssigment, isLoading, cancel};
};

export const useGetUsersForAssignment = (): {
    isLoading: boolean;
    cancel: () => void;
    getUsersForAssignment: (isSecurityAgent: boolean, selectedItemId?: string, filter?: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getUsersForAssignment = useCallback(async (isSecurityAgent: boolean, selectedItemId?: string, filter?: string) => {
        let q = `?isSecurityAgent=${isSecurityAgent}`;

        if (selectedItemId) {
            q += `&selectedItemId=${selectedItemId}`;
        }

        if (filter) {
            q += `&name=${filter}`;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUsersForAssignment${q}`
        });
    }, [execute]);

    return {getUsersForAssignment, isLoading, cancel};
};

export const useGetUsersForAssignmentShort = (): {
    isLoading: boolean;
    cancel: () => void;
    getUsersForAssignmentShort: (selectedItemId?: string, filter?: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getUsersForAssignmentShort = useCallback(async (selectedItemId?: string, filter?: string) => {
        let q = '';

        if (selectedItemId) {
            q += `?selectedItemId=${selectedItemId}`;
        }

        if (filter) {
            q += !selectedItemId
                ? `?name=${filter}`
                : `&name=${filter}`;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUsersForAssignmentShort${q}`
        });
    }, [execute]);

    return {getUsersForAssignmentShort, isLoading, cancel};
};

export const useGetClientsWithGroups = (): {
    isLoading: boolean;
    cancel: () => void;
    getClientsWithGroups: (filter?: string, selectedItem?: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getClientsWithGroups = useCallback(async (filter?: string, selectedItem?: string) => {
        let q = filter
            ? `?filter=${encodeURIComponent(filter)}`
            : '';

        if (selectedItem) {
            if (q === '') {
                q = `?selectedItemId=${selectedItem}`
            } else {
                q = `&selectedItemId=${selectedItem}`
            }
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getClientsWithGroups${q}`
        });
    }, [execute]);

    return {getClientsWithGroups, isLoading, cancel};
};

export const useGetChatServiceProviders = (): {
    isLoading: boolean,
    cancel: () => void,
    getChatServiceProviders: (id?: string, entityId?: string, entityType?: string) => Promise<AxiosResponse<IGridResultResponse<IChatServiceProvider>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<IChatServiceProvider>>({
        method: 'get',
    });

    const getChatServiceProviders = useCallback(async (id?: string, entityId?: string, entityType?: string) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getChatServiceProviders`;

        let params = '';

        if (id && id !== '') {
            params = `?id=${id}`;
        }

        if (entityId && entityId !== '') {
            params += params === ''
                ? `?entityId=${entityId}`
                : `&entityId=${entityId}`;
        }

        if (entityType && entityType !== '') {
            params += params === ''
                ? `?entityType=${entityType}`
                : `&entityType=${entityType}`;
        }

        q += params;

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getChatServiceProviders, isLoading, cancel};
}

export const useGetUsersLoginsForAssigmentAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getUsersLoginsForAssigmentAnonymous: (anonymousUserCode?: string) => Promise<AxiosResponse<Array<SelectModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<Array<SelectModel>>({
        method: 'get',
    });

    const getUsersLoginsForAssigmentAnonymous = useCallback(async (anonymousUserCode?: string) => {

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getUsersLoginsForAssigmentAnonymous`,
            headers: {
                "x-a-asstra-u-code": anonymousUserCode
            }
        });
    }, [execute]);

    return {getUsersLoginsForAssigmentAnonymous, isLoading, cancel};
};

export const useGetResponsibleVisibilityTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getResponsibleVisibilityTree: (filter: string | null, skip?: number, take?: number) => Promise<AxiosResponse<IGridResultResponse<ISelectTreeModel>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IGridResultResponse<ISelectTreeModel>>({
        method: 'get',
    });

    const getResponsibleVisibilityTree = useCallback(async (filter: string | null, skip?: number, take?: number) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getResponsibleVisibilityTree`;

        if (filter && filter !== '') {
            q += `?filter=${filter}`;

            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `&skip=${skip}&take=${take}`;
            }
        } else {
            if (skip !== undefined && skip >= 0 && take !== undefined && take >= 0) {
                q += `?skip=${skip}&take=${take}`;
            }
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getResponsibleVisibilityTree, isLoading, cancel};
};

export const useGetFuelTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getFuelTree: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getFuelTree = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getFuelTree?shipmentGid=${shipmentGid}`
    }), [execute]);

    return {getFuelTree, isLoading, cancel};
};

export const useGetAtmospherePrefilledValues = (): {
    isLoading: boolean,
    cancel: () => void,
    getAtmospherePrefilledValues: (shipmentGid: string, truck: string | null, equipment: string | null) 
        => Promise<AxiosResponse<IDataResponse<IGetAtmospherePrefilledValues>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IGetAtmospherePrefilledValues>>({
        method: 'get',
    });

    const getAtmospherePrefilledValues = useCallback(async (shipmentGid: string, truck: string | null, equipment: string | null) => {
        let q = `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getAtmospherePrefilledValues?shipmentGid=${shipmentGid}`;

        if (truck && truck !== '') {
            q += `&truck=${truck}`;
        }

        if (equipment && equipment !== '') {
            q += `&equipment=${equipment}`;
        }

        return await execute(undefined, {
            url: q
        });
    }, [execute]);

    return {getAtmospherePrefilledValues, isLoading, cancel};
};

export const useGetLoadTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getLoadTree: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getLoadTree = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getLoadTree?shipmentGid=${shipmentGid}`
    }), [execute]);

    return {getLoadTree, isLoading, cancel};
};


export const useGetVehicleForGreenRouterTree = (): {
    isLoading: boolean,
    cancel: () => void,
    getVehicleForGreenRouterTree: (shipmentGid: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getVehicleForGreenRouterTree = useCallback(async (shipmentGid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/dataHelper/getVehicleForGreenRouterTree?shipmentGid=${shipmentGid}`
    }), [execute]);

    return {getVehicleForGreenRouterTree, isLoading, cancel};
};


