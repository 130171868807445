import React from 'react';
import JobGroupHeaderTemplate from "../JobTemplate/JobGroupHeaderTemplate";

const GroupBaseTemplate = (props: any) => {
    if (props.dataItem.field === 'job') {
        return <JobGroupHeaderTemplate {...props} />;
    }

    return (
        props.tdProps && (
            <td {...props.tdProps}
                style={{
                    color: 'black',
                    fontWeight: 'bold'
                }}>
                {props.children && (
                    <p className="k-reset">
                        {props.children.props.children[0]}
                        {props.dataItem[props.field]}
                    </p>
                )}
            </td>
        )
    );
};

export default GroupBaseTemplate;