import {RemarkName} from "../app/enums/RemarkName";
import {trim} from "./string";

export const getRemarkNameResource = (code: string): string => {
    let result: string = '';

    switch (code) {
        case RemarkName.ALTERNATIVE_EQUIPMENT_GROUP:
            result = 'remark.code.alternative-equipment-group';
            break;
        case RemarkName.EQUIPMENT_DETAILS:
        case RemarkName.EBS_AAG_COMMENTS_FROM_CUSTOMER:
            result = 'remark.code.equipment-details';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE:
            result = 'remark.code.comments-for-service-provider-to-print-discharge';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT:
            result = 'remark.code.comments-for-service-provider-to-print-equipment';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_IMP_CUSTOMS:
            result = 'remark.code.comments-for-service-provider-to-print-imp-customs';
            break;
        case RemarkName.LOADING_DOCUMENTS:
            result = 'remark.code.loading-documents';
            break;
        case RemarkName.LOADING_NUMBER:
            result = 'remark.code.loading-number';
            break;
        case RemarkName.COUNTRY_OF_TRANSIT:
            result = 'remark.code.country-of-transit';
            break;
        case RemarkName.COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT:
            result = 'remark.code.comments-for-customer-and-service-provider-to-print';
            break;
        case RemarkName.COMMENTS_FROM_SERVICE_PROVIDER:
            result = 'remark.code.comments-from-service-provider';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT:
            result = 'remark.code.comments-for-service-provider-to-print';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS:
            result = 'remark.code.comments-for-service-provider-to-print-exp-customs';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT:
            result = 'remark.code.comments-for-service-provider-to-print-freight';
            break;
        case RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING:
            result = 'remark.code.comments-for-service-provider-to-print-loading';
            break;
        case RemarkName.COMMENTS:
            result = 'remark.code.comments';
            break;
        case RemarkName.COMMENTS_FOR_SUPPLIER_CABINET:
            result = 'remark.code.comments-for-supplier-cabinet';
            break;

        case RemarkName.COMMENTS_FROM_CURATOR:
            result = 'remark.code.comments-from-curator';
            break;
        case RemarkName.COMMENTS_FOR_REF:
            result = 'remark.code.comments-for-ref';
            break;
        case RemarkName.PLANNED_OFFER_START:
            result = 'remark.code.planned-offer-start';
            break;
        case RemarkName.PLANNED_OFFER_END:
            result = 'remark.code.planned-offer-end';
            break;
        case RemarkName.PAYMENT_TERMS:
            result = 'remark.code.payment-terms';
            break;
        case RemarkName.FROM_SITE:
            result = 'remark.code.from-site';
            break;
        case RemarkName.ADDITIONAL_DELIVERY_REQ:
            result = 'remark.code.additional-delivery-req';
            break;
        case RemarkName.WAREHOUSE_SCHEDULE:
            result = 'remark.code.warehouse-schedule';
            break;

        case RemarkName.COMMENTS_FROM_CUSTOMER:
            result = 'remark.code.comments';
            break;
        case RemarkName.COMMENTS_FROM_FORWARDING_AGENT:
            result = 'remark.code.comments';
            break;
        case RemarkName.COMMENTS_FROM_SALES_AGENT:
            result = 'remark.code.comments';
            break;
        case RemarkName.SHIPMENT_DETAILS:
            result = 'remark.code.comments';
            break;

        case RemarkName.COMMENT_ON_CARGO:
            result = 'remark.code.comments';
            break;
        case RemarkName.ROUTE_COMMENT:
            result = 'remark.code.comments';
            break;
        case RemarkName.COMMENT_ON_DATE:
            result = 'remark.code.comments';
            break;

        case RemarkName.LOADING_WAY:
            result = 'remark.code.loading-way';
            break;
        case RemarkName.PAYER_DESCRIPTION:
            result = 'remark.code.payer-description';
            break;
    }
    return result === ''
        ? ''
        : trim(result, ':', null);
}