import convertToGridConfig, {InitialGridConfig} from "../../../../../components/ui/Grid/Telerik/types/GridConfig";
import GridDefaultHeaderTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultHeaderTemplate/GridDefaultHeaderTemplate";
import GroupBaseTemplate from "../../../Shipment/grid/templates/GroupBaseTemplate/GroupBaseTemplate";
import GridDefaultCellTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultCellTemplate/GridDefaultCellTemplate";
import {UserRole} from "../../../../../app/enums/UserRole";
import {GRID_SELECTED_FIELD} from "../../../../../components/ui/Grid/Telerik/utils/data";
import IsNewTemplate from "../templates/IsNewTemplate/IsNewTemplate";
import OrderReleaseXidTemplate from "../templates/OrderReleaseXidTemplate/OrderReleaseXidTemplate";
import DateTemplate from "../templates/DateTemplate/DateTemplate";
import CountryTemplate from "../templates/CountryTemplate/CountryTemplate";
import EquipmentTemplate from "../templates/EquipmentTemplate/EquipmentTemplate";
import LoadingNumberTemplate from "../templates/LoadingNumberTemplate/LoadingNumberTemplate";
import RefNumTemplate from "../templates/RefNumTemplate/RefNumTemplate";
import CostTemplate from "../templates/CostTemplate/CostTemplate";
import ServiceProviderTemplate from "../templates/ServiceProviderTemplate/ServiceProviderTemplate";
import ManagerTemplate from "../templates/ManagerTemplate/ManagerTemplate";
import TillDateTemplate from "../templates/TillDateTemplate/TillDateTemplate";
import IndicatorTemplate from "../templates/IndicatorTemplate/IndicatorTemplate";
import NotesTemplate from "../templates/NotesTemplate/NotesTemplate";
import CustomerTemplate from "../templates/CustomerTemplate/CustomerTemplate";
import InWorkTemplate from "../templates/InWorkTemplate/InWorkTemplate";
import TagsGridCellTemplate
    from "../../../../../components/shared/components/Tag/components/TagsGridCellTemplate/TagsGridCellTemplate";
import AdrTemplate from "../templates/AdrTemplate/AdrTemplate";
import SimpleDateTemplate from "../templates/SimpleDateTemplate/SimpleDateTemplate";

const initialGridConfig: InitialGridConfig = {
    sortBy: [],
    groupBy: [],
    pageSize: 25,
    headerTemplate: GridDefaultHeaderTemplate,
    cellTemplate: GridDefaultCellTemplate,
    groupTemplate: GroupBaseTemplate,
    columns: [
        {
            visible: true,
            field: 'isNew',
            title: ' ',
            width: 10,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            template: IsNewTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 0},
                {role: UserRole.Forwarder, order: 0},
                {role: UserRole.ServiceProvider, order: 0}
            ]
        },
        {
            visible: true,
            field: GRID_SELECTED_FIELD,
            title: ' ',
            width: 25,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 1},
                {role: UserRole.Forwarder, order: 1},
                {role: UserRole.ServiceProvider, order: 1}
            ]
        },
        {
            visible: true,
            field: 'orderReleaseXid',
            title: 'offer.grid.number',
            width: 250,
            locked: false,
            allowSorting: true,
            template: OrderReleaseXidTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 2},
                {role: UserRole.Forwarder, order: 2},
                {role: UserRole.ServiceProvider, order: 2},
            ]
        },
        {
            visible: true,
            field: 'tags',
            title: 'offer.grid.tags',
            width: 200,
            locked: false,
            allowSorting: true,
            template: TagsGridCellTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 3},
                {role: UserRole.Forwarder, order: 3}
            ]
        },
        {
            visible: true,
            field: 'loadingNumber',
            title: 'offer.grid.loading-number',
            width: 150,
            locked: false,
            allowSorting: true,
            template: LoadingNumberTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 4},
                {role: UserRole.Forwarder, order: 4}
            ]
        },
        {
            visible: true,
            field: 'refNumber',
            title: 'offer.grid.ref-number',
            width: 150,
            locked: false,
            allowSorting: true,
            template: RefNumTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 5},
                {role: UserRole.Forwarder, order: 5}
            ]
        },
        {
            visible: true,
            field: 'betCount',
            title: 'offer.grid.bet-count',
            width: 150,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 6},
                {role: UserRole.Forwarder, order: 6}
            ]
        },
        {
            visible: true,
            field: 'pickupDate',
            title: 'offer.grid.pickup-date',
            width: 150,
            locked: false,
            allowSorting: true,
            template: DateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 7},
                {role: UserRole.Forwarder, order: 7},
                {role: UserRole.ServiceProvider, order: 7}
            ]
        },
        {
            visible: true,
            field: 'deliveryDate',
            title: 'offer.grid.delivery-date',
            width: 150,
            locked: false,
            allowSorting: true,
            template: DateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 8},
                {role: UserRole.Forwarder, order: 8},
                {role: UserRole.ServiceProvider, order: 8}
            ]
        },
        {
            visible: true,
            field: 'tl',
            title: 'offer.grid.tl',
            width: 200,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 9},
                {role: UserRole.Forwarder, order: 9}
            ]
        },
        {
            visible: true,
            field: 'sourceDistrict',
            title: 'offer.grid.source-district',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 10},
                {role: UserRole.Forwarder, order: 10},
                {role: UserRole.ServiceProvider, order: 10}
            ]
        },
        {
            visible: true,
            field: 'destinationDistrict',
            title: 'offer.grid.destination-district',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 11},
                {role: UserRole.Forwarder, order: 11},
                {role: UserRole.ServiceProvider, order: 11}
            ]
        },
        {
            visible: true,
            field: 'sourceCountry2',
            title: 'offer.grid.source-country',
            width: 100,
            locked: false,
            allowSorting: true,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 12},
                {role: UserRole.Forwarder, order: 12},
                {role: UserRole.ServiceProvider, order: 12}
            ]
        },
        {
            visible: true,
            field: 'destinationCountry2',
            title: 'offer.grid.destination-country',
            width: 100,
            locked: false,
            allowSorting: true,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 13},
                {role: UserRole.Forwarder, order: 13},
                {role: UserRole.ServiceProvider, order: 13}
            ]
        },
        {
            visible: true,
            field: 'sourceCity',
            title: 'offer.grid.source-city',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 14},
                {role: UserRole.Forwarder, order: 14},
                {role: UserRole.ServiceProvider, order: 14}
            ]
        },
        {
            visible: true,
            field: 'destinationCity',
            title: 'offer.grid.destination-city',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 15},
                {role: UserRole.Forwarder, order: 15},
                {role: UserRole.ServiceProvider, order: 15}
            ]
        },
        {
            visible: true,
            field: 'auctionBet',
            title: 'offer.grid.auction-bet',
            width: 200,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 16},
                {role: UserRole.Forwarder, order: 16},
                {role: UserRole.ServiceProvider, order: 16},
            ]
        },
        {
            visible: true,
            field: 'weight',
            title: 'offer.grid.weight',
            width: 100,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 17},
                {role: UserRole.Forwarder, order: 17},
                {role: UserRole.ServiceProvider, order: 17}
            ]
        },
        {
            visible: true,
            field: 'equipment',
            title: 'offer.grid.equipment',
            width: 150,
            locked: false,
            allowSorting: true,
            template: EquipmentTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 18},
                {role: UserRole.Forwarder, order: 18},
                {role: UserRole.ServiceProvider, order: 18}
            ]
        },
        {
            visible: true,
            field: 'customer',
            title: 'offer.grid.customer',
            width: 200,
            locked: false,
            allowSorting: true,
            template: CustomerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 19},
                {role: UserRole.Forwarder, order: 19}
            ]
        },
        {
            visible: true,
            field: 'executor',
            title: 'offer.grid.executor',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 20},
                {role: UserRole.Forwarder, order: 20}
            ]
        },
        {
            visible: true,
            field: 'forwarder',
            title: 'offer.grid.forwarderAsstrA',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 20}
            ]
        },
        {
            visible: true,
            field: 'orderManager',
            title: 'offer.grid.order-manager',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 21},
                {role: UserRole.Forwarder, order: 21}
            ]
        },
        {
            visible: true,
            field: 'customerManager',
            title: 'offer.grid.customer-manager',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 22},
                {role: UserRole.Forwarder, order: 22}
            ]
        },








        // {
        //     visible: true,
        //     field: 'transports',
        //     title: 'offer.grid.transports',
        //     width: 200,
        //     locked: false,
        //     allowSorting: true,
        //     template: AvailableTransportTemplate,
        //     roles: [
        //         UserRole.Administrator,
        //         UserRole.Forwarder,
        //         UserRole.ServiceProvider
        //     ],
        //     orderByRoles: [
        //         {role: UserRole.Administrator, order: 7},
        //         {role: UserRole.Forwarder, order: 7},
        //         {role: UserRole.ServiceProvider, order: 6}
        //     ]
        // },









        {
            visible: false,
            field: 'adr',
            title: 'offer.grid.adr',
            width: 100,
            locked: false,
            allowSorting: true,
            template: AdrTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 23},
                {role: UserRole.Forwarder, order: 23},
                {role: UserRole.ServiceProvider, order: 23}
            ]
        },
        {
            visible: false,
            field: 'indicator',
            title: 'offer.grid.indicator',
            width: 75,
            locked: false,
            allowSorting: true,
            template: IndicatorTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 24},
                {role: UserRole.Forwarder, order: 24}
            ]
        },
        {
            visible: false,
            field: 'insertDate',
            title: 'offer.grid.insert-date',
            width: 150,
            locked: false,
            allowSorting: true,
            template: SimpleDateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 25},
                {role: UserRole.Forwarder, order: 25}
            ]
        },
        {
            visible: false,
            field: 'forwarderAgent',
            title: 'offer.grid.forwarder-agent',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 26},
                {role: UserRole.Forwarder, order: 26}
            ]
        },
        {
            visible: false,
            field: 'inWork',
            title: 'offer.grid.in-work',
            width: 75,
            locked: false,
            allowSorting: true,
            template: InWorkTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 27},
                {role: UserRole.Forwarder, order: 27}
            ]
        },
        {
            visible: false,
            field: 'rateOffering',
            title: 'offer.grid.rate-offering',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 28},
                {role: UserRole.Forwarder, order: 28}
            ]
        },
        {
            visible: false,
            field: 'transport',
            title: 'offer.grid.transport',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 29},
                {role: UserRole.Forwarder, order: 29}
            ]
        },
        {
            visible: false,
            field: 'minBet',
            title: 'offer.grid.min-bet',
            width: 150,
            locked: false,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 30},
                {role: UserRole.Forwarder, order: 30}
            ]
        },
        {
            visible: true,
            field: 'bet',
            title: 'offer.grid.bet',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 29}
            ]
        },
        {
            visible: true,
            field: 'tillDate',
            title: 'offer.grid.till-date',
            width: 150,
            locked: false,
            allowSorting: true,
            template: TillDateTemplate,
            roles: [
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 30}
            ]
        },
        {
            visible: false,
            field: 'serviceProvider',
            title: 'offer.grid.service-provider',
            width: 150,
            locked: false,
            allowSorting: true,
            template: ServiceProviderTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 31},
                {role: UserRole.Forwarder, order: 31},
                {role: UserRole.ServiceProvider, order: 31}
            ]
        },
        {
            visible: false,
            field: 'notes',
            title: 'offer.grid.notes',
            width: 200,
            locked: false,
            allowSorting: true,
            template: NotesTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 32},
                {role: UserRole.Forwarder, order: 32}
            ]
        },
        {
            visible: false,
            field: 'offerType',
            title: 'offer.grid.offer-type',
            width: 150,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 33},
                {role: UserRole.Forwarder, order: 33}
            ]
        },

        {
            visible: false,
            field: 'costBrutto',
            title: 'offer.grid.cost-brutto',
            width: 75,
            locked: false,
            allowSorting: true,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 34},
                {role: UserRole.Forwarder, order: 34}
            ]
        },

        {
            visible: false,
            field: 'mainOrderReleaseXid',
            title: 'Leg',
            width: 100,
            constantlyLocked: true,
            locked: true,
            allowSorting: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 100},
                {role: UserRole.Forwarder, order: 100}
            ]
        },

        {
            visible: true,
            field: 'cost',
            title: 'offer.grid.cost',
            width: 205,
            locked: false,
            allowSorting: true,
            template: CostTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 35},
                {role: UserRole.Forwarder, order: 35},
                {role: UserRole.ServiceProvider, order: 35}
            ]
        },
    ]
};

const getOfferInitialGridConfig = (role: UserRole) => {
    return convertToGridConfig(role, {
        groupBy: [...initialGridConfig.groupBy],
        sortBy: [...initialGridConfig.sortBy.map(item => ({...item}))],
        columns: [...initialGridConfig.columns.map(item => ({...item}))],
        pageSize: initialGridConfig.pageSize,
        headerTemplate: initialGridConfig.headerTemplate,
        cellTemplate: initialGridConfig.cellTemplate,
        groupTemplate: initialGridConfig.groupTemplate
    });
};

export default getOfferInitialGridConfig;
