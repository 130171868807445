import React from 'react';
import { SelectModel } from "../../../../../app/types/SelectModel";
import { useTranslation } from "react-i18next";
import GridFilterPanelLayoutCollapse
    from "../../../../uiLayouts/GridFilterPanelLayout/components/GridFilterPanelLayoutCollapse/GridFilterPanelLayoutCollapse";
import cl from "../../AccessSettings/AccessSettings.module.css";
import FilterPanelServiceProviderFilterContent from "./FilterPanelServiceProviderFilterContent";
import Icon from "../../../../../assets/icon/Icon";
import Checkbox, { CheckboxSize } from '../../../../ui/Checkbox/Checkbox';
import { useGridFilterPanelLayoutContext } from '../../../../uiLayouts/GridFilterPanelLayout/hooks/useGridFilterPanelLayoutContext';
import FilterPanelResponsibleVisibilityFilter from '../ResponsibleVisibilityFilter/ResponsibleVisibilityFilter';
import FilterPanelServiceProviderFilterSelectedContainer from "./FilterPanelServiceProviderFilterSelectedContainer";

type FilterPanelServiceProviderFilterProps = {
    id: string;
    disabled?: boolean;
    onClear: (value?: string) => void;
    onChange: (value: Array<SelectModel>) => void,
    value: Array<SelectModel>,
    isShipmentFilter?: boolean;
}

const FilterPanelServiceProviderFilter: React.FC<FilterPanelServiceProviderFilterProps> = ({
    id,
    onClear,
    onChange,
    value,
    disabled = false,
    isShipmentFilter = false,
}) => {
    const { t } = useTranslation();

    const {
        filterToApply,
        applyFilterToApply
    } = useGridFilterPanelLayoutContext();

    return (
        <GridFilterPanelLayoutCollapse titleBold={false}
                                       defaultExpanded={false}
                                       showSeparationHeaderLine={false}
                                       hideOnFilterPanelLayoutContextClear={true}
                                       style={{margin: '5px 0 10px 0'}}
                                       tagsContainer={<FilterPanelServiceProviderFilterSelectedContainer
                                           id={`${id}-tags`}
                                           value={value}
                                           onClear={onClear}/>}
                                       title={
                                           <>
                                               <div>
                                                   {t("shared.labels.service-provider")}
                                               </div>
                                               <div>
                                                   {value.length > 0
                                                       ? <Icon icon={"faTrash"}
                                                               className={cl.trash}
                                                               onClick={(ev) => {
                                                                   ev.stopPropagation();

                                    if (disabled) {
                                        return;
                                    }

                                    onClear();
                                }} />
                            : null
                        }
                    </div>
                </>
            }>
            {({ isExpanded }) =>
                <div>
                    {isExpanded
                        ? <FilterPanelServiceProviderFilterContent id={id}
                                                                   onChange={onChange}
                                                                   value={value}/>
                        : <div style={{height: '250px'}}>
                        </div>
                    }
                    {isShipmentFilter && (
                        <>
                            <div style={{ marginTop: '15px', marginBottom: '10px', fontSize: '14px', color: '#292929' }}>AsstrA Integration</div>

                            <div style={{ display: 'flex', marginBottom: '20px'}}>
                                <Checkbox checked={filterToApply.asstraIntegrationConstant === "true"}
                                    size={CheckboxSize.SmallMedium}
                                    label={t("shared.labels.constant-integration-label")}
                                    change={(val) => {
                                        applyFilterToApply(
                                            'asstraIntegrationConstant',
                                            val === true
                                                ? "true"
                                                : null
                                        );
                                    }}
                                    disabled={false} />

                                <Checkbox checked={filterToApply.asstraIntegrationTemporary === "true"}
                                    size={CheckboxSize.SmallMedium}
                                    label={t("shared.labels.temporary-integration-label")}
                                    change={(val) => {
                                        applyFilterToApply(
                                            'asstraIntegrationTemporary',
                                            val === true
                                                ? "true"
                                                : null
                                        );
                                    }}
                                    disabled={false} />
                            </div>


                            <FilterPanelResponsibleVisibilityFilter id={'responsible-visibility-filter'}
                                onClear={(val) => {
                                    applyFilterToApply('responsibleVisibility', !val
                                        ? null
                                        : filterToApply.responsibleVisibility.filter(e => e.value !== val));
                                }}
                                onChange={(val) => {
                                    applyFilterToApply('responsibleVisibility', val);
                                }}
                                value={filterToApply.responsibleVisibility} />

                        </>
                    )}

                </div>


            }


        </GridFilterPanelLayoutCollapse>
    );
};

export default FilterPanelServiceProviderFilter;