export enum RemarkName {
    ALTERNATIVE_EQUIPMENT_GROUP = "AAG.ALTERNATIVE_EQUIPMENT_GROUP",
    EQUIPMENT_DETAILS = "AAG.EQUIPMENT_DETAILS",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_DISCHARGE",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_IMP_CUSTOMS = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_IMP_CUSTOMS",
    LOADING_DOCUMENTS = "AAG.LOADING_DOCUMENTS",
    LOADING_NUMBER = "AAG.LOADING_NUMBER",
    COUNTRY_OF_TRANSIT = "AAG.COUNTRY_OF_TRANSIT",
    COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT = "AAG.COMMENTS_FOR_CUSTOMER_AND_SERV_PROVIDER_TO_PRINT",
    COMMENTS_FROM_SERVICE_PROVIDER = "AAG.COMMENTS_FROM_SERVICE_PROVIDER",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EXP_CUSTOMS",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_FREIGHT",
    COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING = "AAG.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_LOADING",
    COMMENTS = "AAG.COMMENTS",
    COMMENTS_FOR_SUPPLIER_CABINET = "AAG.COMMENTS_FOR_SUPPLIER_CABINET",
    EBS_AAG_COMMENTS_FROM_CUSTOMER = "AAG.EBS_AAG_COMMENTS_FROM_CUSTOMER",
    LOADING_PLACE_AND_DATE = "AAG.LOADING PLACE AND DATE",
    DISCHARGE_PLACE_AND_DATE = "AAG.DISCHARGE PLACE AND DATE",
    COMMENTS_FROM_CRM = "AAG.COMMENTS_FROM_CRM",
    COMMENTS_FROM_CURATOR = "AAG.COMMENTS_FROM_CURATOR",
    FROM_SITE = "FROM_SITE",
    COMMENTS_FOR_REF = "COMMENTS_FOR_REF",
    PLANNED_OFFER_START = "PLANNED_OFFER_START",
    PLANNED_OFFER_END = "PLANNED_OFFER_END",
    PAYMENT_TERMS = "PAYMENT_TERMS",
    ADDITIONAL_DELIVERY_REQ = "ADDITIONAL_DELIVERY_REQ",
    WAREHOUSE_SCHEDULE = "WAREHOUSE_SCHEDULE",
    SHIPMENT_DETAILS = "AAG.SHIPMENT_DETAILS",
    COMMENTS_FROM_CUSTOMER = "AAG.COMMENTS_FROM_CUSTOMER",
    COMMENTS_FROM_FORWARDING_AGENT = "AAG.COMMENTS_FROM_FORWARDING_AGENT",
    COMMENTS_FROM_SALES_AGENT = "AAG.COMMENTS_FROM_SALES_AGENT",
    FREIGHT_TERMS_CODE = "AAG.FREIGHT_TERMS_CODE",
    COMMENT_ON_CARGO = "COMMENT_ON_CARGO",
    ROUTE_COMMENT = "ROUTE_COMMENT",
    COMMENT_ON_DATE = "COMMENT_ON_DATE",
    PAYER_DESCRIPTION = "PAYER_DESCRIPTION",
    LOADING_WAY = "LOADING_WAY",
    BUY_SHIPMENT_ERROR = "AAG.BUY_SHIPMENT_ERROR",
}
