import {AxiosResponse} from "axios";
import {IDataResponse, IResponse} from "../../interfaces/response/IResponse";
import useAxios from "../../../hooks/useAxios/useAxios";
import {useCallback} from "react";
import {IOffer} from "../../interfaces/offer/IOffer";
import {IClientStatus} from "../../interfaces/offer/IClientStatus";
import {INoteAction} from "../../interfaces/offer/INoteAction";
import {IChangeRefNumberGetAction} from "../../interfaces/offer/IChangeRefNumberGetAction";
import {IChangeRefNumberPostAction} from "../../interfaces/offer/IChangeRefNumberPostAction";
import {IChangeDatePostAction} from "../../interfaces/offer/IChangeDatePostAction";
import {IChangeDateGetAction} from "../../interfaces/offer/IChangeDateGetAction";
import {IChangeEquipmentPostAction} from "../../interfaces/offer/IChangeEquipmentPostAction";
import {IChangeEquipmentGetAction} from "../../interfaces/offer/IChangeEquipmentGetAction";
import {ITransportsAction} from "../../interfaces/offer/ITransportsAction";
import {IFreeStockAction} from "../../interfaces/offer/IFreeStockAction";
import {IResetAssigmentAction} from "../../interfaces/offer/IResetAssigmentAction";
import {ICancelSpotGetAction} from "../../interfaces/offer/ICancelSpotGetAction";
import {ICancelSpotPostAction} from "../../interfaces/offer/ICancelSpotPostAction";
import {IAssignOfferGetAction} from "../../interfaces/offer/IAssignOfferGetAction";
import {IAssignOfferPostAction} from "../../interfaces/offer/IAssignOfferPostAction";
import {ISendContractGetAction} from "../../interfaces/offer/ISendContractGetAction";
import {ISendContractPostAction} from "../../interfaces/offer/ISendContractPostAction";
import {IOfferDeleteAction} from "../../interfaces/offer/IOfferDeleteAction";
import {IConfirmAuctionGetAction} from "../../interfaces/offer/IConfirmAuctionGetAction";
import {IConfirmAuctionPostAction} from "../../interfaces/offer/IConfirmAuctionPostAction";
import {IConfirmAuctionContractGetAction} from "../../interfaces/offer/IConfirmAuctionContractGetAction";
import {IConfirmAuctionContractPostAction} from "../../interfaces/offer/IConfirmAuctionContractPostAction";
import {IRecallBetGetAction} from "../../interfaces/offer/IRecallBetGetAction";
import {IRecallBetPostAction} from "../../interfaces/offer/IRecallBetPostAction";
import {IAddBetGetAction} from "../../interfaces/offer/IAddBetGetAction";
import {IAddBetPostAction} from "../../interfaces/offer/IAddBetPostAction";
import {IRemarkGetAction} from "../../interfaces/offer/IRemarkGetAction";
import {IRemarkPostAction} from "../../interfaces/offer/IRemarkPostAction";
import {IRemarkDeleteAction} from "../../interfaces/offer/IRemarkDeleteAction";
import {ISelectBetGetAction} from "../../interfaces/offer/ISelectBetGetAction";
import {ISelectBetPostAction} from "../../interfaces/offer/ISelectBetPostAction";
import {SelectModel} from "../../types/SelectModel";
import {ITransEuLogin} from "../../interfaces/shared/ITransEuLogin";

export const useGetOffer = (): {
    isLoading: boolean,
    cancel: () => void,
    getOffer: (id: string) => Promise<AxiosResponse<IDataResponse<IOffer>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IOffer>>({
        method: 'get',
    });

    const getOffer = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getOffer?id=${id}`
    }), [execute]);

    return {getOffer, isLoading, cancel};
};

export const useGetOfferAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getOfferAnonymous: (id: string) => Promise<AxiosResponse<IDataResponse<IOffer>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IOffer>>({
        method: 'get',
    });

    const getOfferAnonymous = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getOfferAnonymous?id=${id}`
    }), [execute]);

    return {getOfferAnonymous, isLoading, cancel};
};

export const useMarkOffersAsViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markOffersAsViewed: (params: {
        ids: Array<number>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<number>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/markAsViewedAction`
    });

    const markOffersAsViewed = useCallback(async (params: {
        ids: Array<number>
    }) => await execute(params), [execute]);

    return {markOffersAsViewed, isLoading, cancel};
};

export const useMarkOffersAsNotViewed = (): {
    isLoading: boolean,
    cancel: () => void,
    markOffersAsNotViewed: (params: {
        ids: Array<number>
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        ids: Array<number>
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/markAsNotViewedAction`
    });

    const markOffersAsNotViewed = useCallback(async (params: {
        ids: Array<number>
    }) => await execute(params), [execute]);

    return {markOffersAsNotViewed, isLoading, cancel};
};


export const useGetOfferManagementLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getOfferManagementLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getOfferManagementLink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getOfferManagementLink?id=${id}`
    }), [execute]);

    return {getOfferManagementLink, isLoading, cancel};
};

export const useGetTransEULink = (): {
    isLoading: boolean,
    cancel: () => void,
    getTransEULink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getTransEULink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getTransEULink?id=${id}`
    }), [execute]);

    return {getTransEULink, isLoading, cancel};
};

export const useWorkPostAction = (): {
    isLoading: boolean,
    cancel: () => void,
    workPostAction: (params: {
        orderReleaseId: number
    }) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, {
        orderReleaseId: number
    }>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/inWorkDataAction`
    });

    const workPostAction = useCallback(async (params: {
        orderReleaseId: number
    }) => await execute(params), [execute]);

    return {workPostAction, isLoading, cancel};
};

export const useGetClientStatus = (): {
    isLoading: boolean,
    cancel: () => void,
    getClientStatus: (id: string) => Promise<AxiosResponse<IDataResponse<IClientStatus>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IClientStatus>>({
        method: 'get',
    });

    const getClientStatus = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getClientStatusData?id=${id}`
    }), [execute]);

    return {getClientStatus, isLoading, cancel};
};

export const useSaveNote = (): {
    isLoading: boolean,
    cancel: () => void,
    saveNote: (params: INoteAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, INoteAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/saveNoteData`
    });

    const saveNote = useCallback(async (params: INoteAction) => await execute(params), [execute]);

    return {saveNote, isLoading, cancel};
};

export const useGetChangeNoteData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeNoteData: (id: string) => Promise<AxiosResponse<IDataResponse<INoteAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<INoteAction>>({
        method: 'get',
    });

    const getChangeNoteData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getNoteData?id=${id}`
    }), [execute]);

    return {getChangeNoteData, isLoading, cancel};
}

export const useSaveRefNumberData = (): {
    isLoading: boolean,
    cancel: () => void,
    saveRefNumberData: (params: IChangeRefNumberPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeRefNumberPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/saveRefNum`
    });

    const saveRefNumberData = useCallback(async (params: IChangeRefNumberPostAction) => await execute(params), [execute]);

    return {saveRefNumberData, isLoading, cancel};
};

export const useGetRefNumberData = (): {
    isLoading: boolean,
    cancel: () => void,
    getRefNumberData: (id: string) => Promise<AxiosResponse<IDataResponse<IChangeRefNumberGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeRefNumberGetAction>>({
        method: 'get',
    });

    const getRefNumberData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getRefNumData?id=${id}`
    }), [execute]);

    return {getRefNumberData, isLoading, cancel};
}

export const useChangePlannedData = (): {
    isLoading: boolean,
    cancel: () => void,
    changePlannedDate: (params: IChangeDatePostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeDatePostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/changeDateAction`
    });

    const changePlannedDate = useCallback(async (params: IChangeDatePostAction) => await execute(params), [execute]);

    return {changePlannedDate, isLoading, cancel};
};

export const useGetChangeDateData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeDateData: (id: string) => Promise<AxiosResponse<IDataResponse<IChangeDateGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeDateGetAction>>({
        method: 'get',
    });

    const getChangeDateData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getChangeDateData?id=${id}`
    }), [execute]);

    return {getChangeDateData, isLoading, cancel};
}

export const useChangeEquipmentGroup = (): {
    isLoading: boolean,
    cancel: () => void,
    changeEquipmentGroup: (params: IChangeEquipmentPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IChangeEquipmentPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/saveEquipmentGroupAction`
    });

    const changeEquipmentGroup = useCallback(async (params: IChangeEquipmentPostAction) => await execute(params), [execute]);

    return {changeEquipmentGroup, isLoading, cancel};
};

export const useGetChangeEquipmentGroupData = (): {
    isLoading: boolean,
    cancel: () => void,
    getChangeEquipmentGroupData: (id: string) => Promise<AxiosResponse<IDataResponse<IChangeEquipmentGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IChangeEquipmentGetAction>>({
        method: 'get',
    });

    const getChangeEquipmentGroupData = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getEquipmentGroupData?id=${id}`
    }), [execute]);

    return {getChangeEquipmentGroupData, isLoading, cancel};
}

export const useGetTransporeonBookingLink = (): {
    isLoading: boolean,
    cancel: () => void,
    getTransporeonBookingLink: (id: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const getTransporeonBookingLink = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getTransporeonBookingLink?id=${id}`
    }), [execute]);

    return {getTransporeonBookingLink, isLoading, cancel};
};

export const useGetAcceptTransportsData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAcceptTransportsData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ITransportsAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransportsAction>>({
        method: 'get',
    });

    const getAcceptTransportsData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getAcceptTransportsData?id=${param}`
        });
    }, [execute]);

    return {getAcceptTransportsData, isLoading, cancel};
}

export const useGetDeclineTransportsData = (): {
    isLoading: boolean;
    cancel: () => void;
    getDeclineTransportsData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ITransportsAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransportsAction>>({
        method: 'get',
    });

    const getDeclineTransportsData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getDeclineTransportsData?id=${param}`
        });
    }, [execute]);

    return {getDeclineTransportsData, isLoading, cancel};
}

export const useAcceptTransportsAction = (): {
    isLoading: boolean,
    cancel: () => void,
    acceptTransports: (params: ITransportsAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ITransportsAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/acceptTransportsAction`
    });

    const acceptTransports = useCallback(async (params: ITransportsAction) => await execute(params), [execute]);

    return {acceptTransports, isLoading, cancel};
};

export const useDeclineTransportsAction = (): {
    isLoading: boolean,
    cancel: () => void,
    declineTransports: (params: ITransportsAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ITransportsAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/declineTransportsAction`
    });

    const declineTransports = useCallback(async (params: ITransportsAction) => await execute(params), [execute]);

    return {declineTransports, isLoading, cancel};
};

export const useGetSendToFreeStockActionData = (): {
    isLoading: boolean,
    cancel: () => void,
    getSendToFreeStockActionData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IFreeStockAction>> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransportsAction>>({
        method: 'get',
    });

    const getSendToFreeStockActionData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getSendToFreeStockData?id=${param}`
        });
    }, [execute]);

    return {getSendToFreeStockActionData, isLoading, cancel};
};

export const useSendToFreeStock = (): {
    isLoading: boolean,
    cancel: () => void,
    sendToFreeStock: (params: IFreeStockAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IFreeStockAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/sendToFreeStockAction`
    });

    const sendToFreeStock = useCallback(async (params: IFreeStockAction) => await execute(params), [execute]);

    return {sendToFreeStock, isLoading, cancel};
};

export const useGetRemoveFromFreeStockActionData = (): {
    isLoading: boolean,
    cancel: () => void,
    getRemoveFromFreeStockActionData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IFreeStockAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransportsAction>>({
        method: 'get',
    });

    const getRemoveFromFreeStockActionData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getRemoveFromFreeStockData?id=${param}`
        });
    }, [execute]);

    return {getRemoveFromFreeStockActionData, isLoading, cancel};
};

export const useRemoveFromFreeStock = (): {
    isLoading: boolean,
    cancel: () => void,
    removeFromFreeStock: (params: IFreeStockAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IFreeStockAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/removeFromFreeStockAction`
    });

    const removeFromFreeStock = useCallback(async (params: IFreeStockAction) => await execute(params), [execute]);

    return {removeFromFreeStock, isLoading, cancel};
};

export const useGetResetAssigmentActionData = (): {
    isLoading: boolean,
    cancel: () => void,
    getResetAssigmentData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IResetAssigmentAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IResetAssigmentAction>>({
        method: 'get',
    });

    const getResetAssigmentData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getResetAssigmentData?id=${param}`
        });
    }, [execute]);

    return {getResetAssigmentData, isLoading, cancel};
};

export const useResetAssigment = (): {
    isLoading: boolean,
    cancel: () => void,
    resetAssigment: (params: IResetAssigmentAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IResetAssigmentAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/resetAssigmentAction`
    });

    const resetAssigment = useCallback(async (params: IResetAssigmentAction) => await execute(params), [execute]);

    return {resetAssigment, isLoading, cancel};
};

export const useGetCancelSpotDataAction = (): {
    isLoading: boolean,
    cancel: () => void,
    getCancelSpotData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ICancelSpotGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ICancelSpotGetAction>>({
        method: 'get',
    });

    const getCancelSpotData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getCancelData?id=${param}`
        });
    }, [execute]);

    return {getCancelSpotData, isLoading, cancel};
};

export const useCancelSpot = (): {
    isLoading: boolean,
    cancel: () => void,
    cancelSpot: (params: ICancelSpotPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ICancelSpotPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/cancelAction`
    });

    const cancelSpot = useCallback(async (params: ICancelSpotPostAction) => await execute(params), [execute]);

    return {cancelSpot, isLoading, cancel};
};

export const useGetAssignData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAssignData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<IAssignOfferGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAssignOfferGetAction>>({
        method: 'get',
    });

    const getAssignData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getAssignOfferData?id=${param}`
        });
    }, [execute]);

    return {getAssignData, isLoading, cancel};
}

export const useAssignOffer = (): {
    isLoading: boolean,
    cancel: () => void,
    assignOffer: (params: IAssignOfferPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAssignOfferPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/assignOfferAction`
    });

    const assignOffer = useCallback(async (params: IAssignOfferPostAction) => await execute(params), [execute]);

    return {assignOffer, isLoading, cancel};
};

export const useGetSendContractData = (): {
    isLoading: boolean;
    cancel: () => void;
    getSendContractData: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ISendContractGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISendContractGetAction>>({
        method: 'get',
    });

    const getSendContractData = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getSendContractData?id=${param}`
        });
    }, [execute]);

    return {getSendContractData, isLoading, cancel};
}

export const useSendContract = (): {
    isLoading: boolean,
    cancel: () => void,
    sendContract: (params: ISendContractPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISendContractPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/sendContractAction`
    });

    const sendContract = useCallback(async (params: ISendContractPostAction) => await execute(params), [execute]);

    return {sendContract, isLoading, cancel};
};

export const useGetDeleteActionData = (): {
    isLoading: boolean;
    cancel: () => void;
    getDeleteData: (id: string) => Promise<AxiosResponse<IDataResponse<IOfferDeleteAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IOfferDeleteAction>>({
        method: 'get',
    });

    const getDeleteData = useCallback(async (id: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getDeleteData?id=${id}`
        });
    }, [execute]);

    return {getDeleteData, isLoading, cancel};
}

export const useDeleteAction = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteAction: (params: IOfferDeleteAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IOfferDeleteAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/deleteAction`
    });

    const deleteAction = useCallback(async (params: IOfferDeleteAction) => await execute(params), [execute]);

    return {deleteAction, isLoading, cancel};
};

export const useGetConfirmAuctionData = (): {
    isLoading: boolean;
    cancel: () => void;
    getConfirmAuctionData: (id: string | Array<string>, spId: string | null) => Promise<AxiosResponse<IDataResponse<IConfirmAuctionGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IConfirmAuctionGetAction>>({
        method: 'get',
    });

    const getConfirmAuctionData = useCallback(async (id: string | Array<string>, spId: string | null) => {
        let q: string;

        if (Array.isArray(id)) {
            q = `id=${id.join(';')}`;
        } else {
            q = `id=${id}`;
        }

        if (spId !== null && spId !== '') {
            q += `&spId=${spId}`;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getConfirmAuctionData?${q}`
        });
    }, [execute]);

    return {getConfirmAuctionData, isLoading, cancel};
}

export const useConfirmAuction = (): {
    isLoading: boolean,
    cancel: () => void,
    confirmAuction: (params: IConfirmAuctionPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmAuctionPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/confirmAuctionAction`
    });

    const confirmAuction = useCallback(async (params: IConfirmAuctionPostAction) => await execute(params), [execute]);

    return {confirmAuction, isLoading, cancel};
};

export const useGetConfirmAuctionContractData = (): {
    isLoading: boolean;
    cancel: () => void;
    getConfirmAuctionContractData: (id: string) => Promise<AxiosResponse<IDataResponse<IConfirmAuctionContractGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IConfirmAuctionContractGetAction>>({
        method: 'get',
    });

    const getConfirmAuctionContractData = useCallback(async (id: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getConfirmAuctionByForwarderData?id=${id}`
        });
    }, [execute]);

    return {getConfirmAuctionContractData, isLoading, cancel};
}

export const useConfirmAuctionContract = (): {
    isLoading: boolean,
    cancel: () => void,
    confirmAuctionContract: (params: IConfirmAuctionContractPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmAuctionContractPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/confirmAuctionByForwarderAction`
    });

    const confirmAuctionContract = useCallback(async (params: IConfirmAuctionContractPostAction) => await execute(params), [execute]);

    return {confirmAuctionContract, isLoading, cancel};
};

export const useGetRecallBetData = (): {
    isLoading: boolean;
    cancel: () => void;
    getRecallBetData: (id: number) => Promise<AxiosResponse<IDataResponse<IRecallBetGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IRecallBetGetAction>>({
        method: 'get',
    });

    const getRecallBetData = useCallback(async (id: number) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getRecallBet?id=${id}`
        });
    }, [execute]);

    return {getRecallBetData, isLoading, cancel};
}

export const useRecallBet = (): {
    isLoading: boolean,
    cancel: () => void,
    recallBet: (params: IRecallBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRecallBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/recallBetAction`
    });

    const recallBet = useCallback(async (params: IRecallBetPostAction) => await execute(params), [execute]);

    return {recallBet, isLoading, cancel};
};

export const useGetAddBetData = (): {
    isLoading: boolean;
    cancel: () => void;
    getAddBetData: (id: string) => Promise<AxiosResponse<IDataResponse<IAddBetGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAddBetGetAction>>({
        method: 'get',
    });

    const getAddBetData = useCallback(async (id: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getAddBetData?id=${id}`
        });
    }, [execute]);

    return {getAddBetData, isLoading, cancel};
}

export const useAddBet = (): {
    isLoading: boolean,
    cancel: () => void,
    addBet: (params: IAddBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAddBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/addBetAction`
    });

    const addBet = useCallback(async (params: IAddBetPostAction) => await execute(params), [execute]);

    return {addBet, isLoading, cancel};
};

export const useGetEditRemarkData = (): {
    isLoading: boolean;
    cancel: () => void;
    getEditRemarkData: (id: string, code: string | null, seq: string | null) => Promise<AxiosResponse<IDataResponse<IRemarkGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IRemarkGetAction>>({
        method: 'get',
    });

    const getEditRemarkData = useCallback(async (id: string, code: string | null, seq: string | null) => {
        let q = `id=${id}`;

        if (code) {
            q += `&code=${code}`;
        }

        if (seq) {
            q += `&seq=${seq}`;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getRemarkData?${q}`
        });
    }, [execute]);

    return {getEditRemarkData, isLoading, cancel};
}

export const useEditRemark = (): {
    isLoading: boolean,
    cancel: () => void,
    editRemark: (params: IRemarkPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRemarkPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/saveRemarkAction`
    });

    const editRemark = useCallback(async (params: IRemarkPostAction) => await execute(params), [execute]);

    return {editRemark, isLoading, cancel};
};

export const useDeleteRemark = (): {
    isLoading: boolean,
    cancel: () => void,
    deleteRemark: (params: IRemarkDeleteAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRemarkDeleteAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/deleteRemarkAction`
    });

    const deleteRemark = useCallback(async (params: IRemarkDeleteAction) => await execute(params), [execute]);

    return {deleteRemark, isLoading, cancel};
};

export const useGetSelectBetData = (): {
    isLoading: boolean;
    cancel: () => void;
    getSelectBetData: (id: number) => Promise<AxiosResponse<IDataResponse<ISelectBetGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISelectBetGetAction>>({
        method: 'get',
    });

    const getSelectBetData = useCallback(async (id: number) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getSelectBetData?id=${id}`
        });
    }, [execute]);

    return {getSelectBetData, isLoading, cancel};
}

export const useSelectBet = (): {
    isLoading: boolean,
    cancel: () => void,
    selectBet: (params: ISelectBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISelectBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/selectBetAction`
    });

    const selectBet = useCallback(async (params: ISelectBetPostAction) => await execute(params), [execute]);

    return {selectBet, isLoading, cancel};
};

export const useGetServiceProviders = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProviders: (name: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getServiceProviders = useCallback(async (name: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getServiceProviders?filter=${name}`
    }), [execute]);

    return {getServiceProviders, isLoading, cancel};
};

export const useGetCheckForwarderForTransEUAccess = (): {
    isLoading: boolean,
    cancel: () => void,
    checkForwarderForTransEUAccess: (userId: string) => Promise<AxiosResponse<IDataResponse<boolean>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<boolean>>({
        method: 'get',
    });

    const checkForwarderForTransEUAccess = useCallback(async (userId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/checkForwarderForTransEUAccess?userId=${userId}`
    }), [execute]);

    return {checkForwarderForTransEUAccess, isLoading, cancel};
};

export const useGetTransEULoginData = (): {
    isLoading: boolean,
    cancel: () => void,
    getTransEULoginData: (userId: string) => Promise<AxiosResponse<IDataResponse<ITransEuLogin>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransEuLogin>>({
        method: 'get',
    });

    const getTransEULoginData = useCallback(async (userId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getTransEUAccount?userId=${userId}`
    }), [execute]);

    return {getTransEULoginData, isLoading, cancel};
};

export const useGetValidateExecutionAgent = (): {
    isLoading: boolean,
    cancel: () => void,
    validateExecutionAgent: (forwarder: string, rid: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const validateExecutionAgent = useCallback(async (forwarder: string, rid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/validateExecutionAgent?fwd=${forwarder}&rid=${rid}`
    }), [execute]);

    return {validateExecutionAgent, isLoading, cancel};
};

export const useGetValidateClient = (): {
    isLoading: boolean,
    cancel: () => void,
    validateClient: (id: string) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const validateClient = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/validateClientData?id=${id}`
    }), [execute]);

    return {validateClient, isLoading, cancel};
};

export const useGetFreeStockOffersCount = (): {
    isLoading: boolean,
    cancel: () => void,
    getFreeStockOffersCount: () => Promise<AxiosResponse<IDataResponse<number>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<number>>({
        method: 'get',
    });

    const getFreeStockOffersCount = useCallback(async () => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getFreeStockOffersCount`
    }), [execute]);

    return {getFreeStockOffersCount, isLoading, cancel};
};

export const useGetSendContractDataAnonymous = (): {
    isLoading: boolean;
    cancel: () => void;
    getSendContractDataAnonymous: (id: string | Array<string>) => Promise<AxiosResponse<IDataResponse<ISendContractGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ISendContractGetAction>>({
        method: 'get',
    });

    const getSendContractDataAnonymous = useCallback(async (id: string | Array<string>) => {

        let param: string;

        if (Array.isArray(id)) {
            param = id.join(';');
        } else {
            param = id;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getSendContractDataAnonymous?id=${param}`
        });
    }, [execute]);

    return {getSendContractDataAnonymous, isLoading, cancel};
}

export const useSendContractAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    sendContractAnonymous: (params: ISendContractPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, ISendContractPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/sendContractActionAnonymous`
    });

    const sendContractAnonymous = useCallback(async (params: ISendContractPostAction) => await execute(params), [execute]);

    return {sendContractAnonymous, isLoading, cancel};
};

export const useGetValidateExecutionAgentAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    validateExecutionAgentAnonymous: (forwarder: string, rid: string) => Promise<AxiosResponse<IDataResponse<string>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<string>>({
        method: 'get',
    });

    const validateExecutionAgentAnonymous = useCallback(async (forwarder: string, rid: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/validateExecutionAgentAnonymous?fwd=${forwarder}&rid=${rid}`
    }), [execute]);

    return {validateExecutionAgentAnonymous, isLoading, cancel};
};

export const useGetValidateClientAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    validateClientAnonymous: (id: string) => Promise<AxiosResponse<IResponse> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse>({
        method: 'get',
    });

    const validateClientAnonymous = useCallback(async (id: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/validateClientDataAnonymous?id=${id}`
    }), [execute]);

    return {validateClientAnonymous, isLoading, cancel};
};

export const useGetCheckForwarderForTransEUAccessAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    checkForwarderForTransEUAccessAnonymous: (userId: string) => Promise<AxiosResponse<IDataResponse<boolean>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<boolean>>({
        method: 'get',
    });

    const checkForwarderForTransEUAccessAnonymous = useCallback(async (userId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/checkForwarderForTransEUAccessAnonymous?userId=${userId}`
    }), [execute]);

    return {checkForwarderForTransEUAccessAnonymous, isLoading, cancel};
};

export const useGetTransEULoginDataAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getTransEULoginDataAnonymous: (userId: string) => Promise<AxiosResponse<IDataResponse<ITransEuLogin>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<ITransEuLogin>>({
        method: 'get',
    });

    const getTransEULoginDataAnonymous = useCallback(async (userId: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getTransEUAccountAnonymous?userId=${userId}`
    }), [execute]);

    return {getTransEULoginDataAnonymous, isLoading, cancel};
};

export const useGetServiceProvidersAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    getServiceProvidersAnonymous: (name: string) => Promise<AxiosResponse<IDataResponse<Array<SelectModel>>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<Array<SelectModel>>>({
        method: 'get',
    });

    const getServiceProvidersAnonymous = useCallback(async (name: string) => await execute(undefined, {
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/getServiceProvidersAnonymous?filter=${name}`
    }), [execute]);

    return {getServiceProvidersAnonymous, isLoading, cancel};
};


export const useGetAddBetDataAnonymous = (): {
    isLoading: boolean;
    cancel: () => void;
    getAddBetDataAnonymous: (id: string) => Promise<AxiosResponse<IDataResponse<IAddBetGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IAddBetGetAction>>({
        method: 'get',
    });

    const getAddBetDataAnonymous = useCallback(async (id: string) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getAddBetDataAnonymous?id=${id}`
        });
    }, [execute]);

    return {getAddBetDataAnonymous, isLoading, cancel};
}

export const useAddBetAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    addBetAnonymous: (params: IAddBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IAddBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/addBetActionAnonymous`
    });

    const addBetAnonymous = useCallback(async (params: IAddBetPostAction) => await execute(params), [execute]);

    return {addBetAnonymous, isLoading, cancel};
};


export const useGetRecallBetDataAnonymous = (): {
    isLoading: boolean;
    cancel: () => void;
    getRecallBetDataAnonymous: (id: number) => Promise<AxiosResponse<IDataResponse<IRecallBetGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IRecallBetGetAction>>({
        method: 'get',
    });

    const getRecallBetDataAnonymous = useCallback(async (id: number) => {
        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getRecallBetAnonymous?id=${id}`
        });
    }, [execute]);

    return {getRecallBetDataAnonymous, isLoading, cancel};
}

export const useRecallBetAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    recallBetAnonymous: (params: IRecallBetPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IRecallBetPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/recallBetActionAnonymous`
    });

    const recallBetAnonymous = useCallback(async (params: IRecallBetPostAction) => await execute(params), [execute]);

    return {recallBetAnonymous, isLoading, cancel};
};


export const useGetConfirmAuctionDataAnonymous = (): {
    isLoading: boolean;
    cancel: () => void;
    getConfirmAuctionDataAnonymous: (id: string | Array<string>, spId: string | null) => Promise<AxiosResponse<IDataResponse<IConfirmAuctionGetAction>> | undefined>
} => {
    const {execute, isLoading, cancel} = useAxios<IDataResponse<IConfirmAuctionGetAction>>({
        method: 'get',
    });

    const getConfirmAuctionDataAnonymous = useCallback(async (id: string | Array<string>, spId: string | null) => {
        let q: string;

        if (Array.isArray(id)) {
            q = `id=${id.join(';')}`;
        } else {
            q = `id=${id}`;
        }

        if (spId !== null && spId !== '') {
            q += `&spId=${spId}`;
        }

        return await execute(undefined, {
            url: `api/${process.env.REACT_APP_API_VERSION}/offer/getConfirmAuctionDataAnonymous?${q}`
        });
    }, [execute]);

    return {getConfirmAuctionDataAnonymous, isLoading, cancel};
}

export const useConfirmAuctionAnonymous = (): {
    isLoading: boolean,
    cancel: () => void,
    confirmAuctionAnonymous: (params: IConfirmAuctionPostAction) => Promise<AxiosResponse<IResponse> | undefined>;
} => {
    const {execute, isLoading, cancel} = useAxios<IResponse, IConfirmAuctionPostAction>({
        method: 'POST',
        url: `api/${process.env.REACT_APP_API_VERSION}/offer/confirmAuctionActionAnonymous`
    });

    const confirmAuctionAnonymous = useCallback(async (params: IConfirmAuctionPostAction) => await execute(params), [execute]);

    return {confirmAuctionAnonymous, isLoading, cancel};
};
