import React from 'react';
import {IOffer} from "../../../../../../app/interfaces/offer/IOffer";
import RateOffering from "../fields/RateOffering/RateOffering";
import LoadingNumber from "../fields/LoadingNumber/LoadingNumber";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";
import EquipmentGroup from "../fields/EquipmentGroup/EquipmentGroup";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {getRemarkNameResource} from "../../../../../../helpers/remark";
import {getUserRole} from "../../../../../../helpers/user";
import {useAppSelector} from "../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../features/authentication/authenticationSliceSelectors";
import {RemarkName} from "../../../../../../app/enums/RemarkName";
import {useTranslation} from "react-i18next";

type OfferDetailsProps = {
    offer: IOffer;
};

const OfferDetails: React.FC<OfferDetailsProps> = ({offer}) => {
    const isMaxWidth600 = useMediaQuery('(max-width:600px)');
    const {t} = useTranslation();
    const user = useAppSelector(selectCurrentUser);
    if (!user) {
        return null;
    }
    let role = getUserRole(user);

    let alternativeEquipmentGroupRemark = offer.transportRemarks.length > 0
        ? offer.transportRemarks.find(e => e.code === RemarkName.ALTERNATIVE_EQUIPMENT_GROUP)
        : undefined;

    let commentsForServiceProviderRemark = offer.transportRemarks.length > 0
        ? offer.transportRemarks.find(e => e.code === RemarkName.COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT)
        : undefined

    return (
        <div className={'details-box-default-container'}>
            <div className={'details-box-default-container-columns'}
                 data-count={isMaxWidth600 ? '1' : '2'}>
                {!isMaxWidth600 &&
                    <>
                        <div>
                            {/*<div className={'details-box-default-container-item'}>
                                <RateOffering offer={offer}/>
                            </div>*/}
                            <div className={'details-box-default-container-item'}>
                                <EquipmentGroup offer={offer}/>
                            </div>

                        </div>
                        <div>

                            {role !== UserRole.ServiceProvider &&<div className={'details-box-default-container-item'}>
                                <LoadingNumber offer={offer}/>
                            </div>
                            }
                            {role !== UserRole.ServiceProvider &&
                                <>
                                    {alternativeEquipmentGroupRemark &&
                                        <div className={'ALTERNATIVE_EQUIPMENT_GROUP mgb-5'}>
                                            <label className={'view-label'}>
                                                {t(getRemarkNameResource(alternativeEquipmentGroupRemark.code ?? ''))}
                                            </label>
                                            <span>
                                {alternativeEquipmentGroupRemark.text}
                            </span>
                                        </div>
                                    }

                                    {commentsForServiceProviderRemark &&
                                        <div className={'COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT mgb-5'}>
                                            <label className={'view-label'}>
                                                {t(getRemarkNameResource(commentsForServiceProviderRemark.code ?? ''))}
                                            </label>
                                            <span>
                                {commentsForServiceProviderRemark.text}
                            </span>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </>
                }

                {isMaxWidth600 &&
                    <div>
                        {/*<div className={'details-box-default-container-item'}>
                            <RateOffering offer={offer}/>
                        </div>*/}
                        <div className={'details-box-default-container-item'}>
                            <EquipmentGroup offer={offer}/>
                        </div>
                        {role !== UserRole.ServiceProvider &&
                        <div className={'details-box-default-container-item'}>
                            <LoadingNumber offer={offer}/>
                        </div>
                        }

                        {role !== UserRole.ServiceProvider &&
                            <>
                                {alternativeEquipmentGroupRemark &&
                                    <div className={'ALTERNATIVE_EQUIPMENT_GROUP mgb-5'}>
                                        <label className={'view-label'}>
                                            {t(getRemarkNameResource(alternativeEquipmentGroupRemark.code ?? ''))}
                                        </label>
                                        <span>
                                {alternativeEquipmentGroupRemark.text}
                            </span>
                                    </div>
                                }

                                {commentsForServiceProviderRemark &&
                                    <div className={'COMMENTS_FOR_SERVICE_PROVIDER_TO_PRINT_EQUIPMENT mgb-5'}>
                                        <label className={'view-label'}>
                                            {t(getRemarkNameResource(commentsForServiceProviderRemark.code ?? ''))}
                                        </label>
                                        <span>
                                {commentsForServiceProviderRemark.text}
                            </span>
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default OfferDetails;
