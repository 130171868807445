import React, {SetStateAction} from 'react';
import {IAssignOfferGetAction} from "../../../../../../app/interfaces/offer/IAssignOfferGetAction";
import {IAssignOfferPostAction} from "../../../../../../app/interfaces/offer/IAssignOfferPostAction";
import SelectedOffers from "../../../form/components/SelectedOffers/SelectedOffers";
import {useTranslation} from "react-i18next";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import Checkbox from "../../../../../../components/ui/Checkbox/Checkbox";
import AssignOfferFormManagerSelector from "./AssignOfferFormManagerSelector";

type AssignOfferFormProps = {
    model: IAssignOfferGetAction;
    disabled: boolean;
    setIsSubmitDisabled: React.Dispatch<SetStateAction<boolean>>;
    save: (model: IAssignOfferPostAction) => Promise<void>;
};

const label: React.CSSProperties = {
    display: 'flex',
    alignItems: 'baseline',
    gap: '5px'
}

const AssignOfferForm: React.FC<AssignOfferFormProps> = ({
                                                             model,
                                                             disabled,
                                                             setIsSubmitDisabled,
                                                             save
                                                         }) => {
    const {t} = useTranslation();

    const form = useFormik<IAssignOfferPostAction>({
        initialValues: {
            orderReleaseGid: model.orderReleaseGid,
            hasExecutionAgent: false,
            executionAgent: model.executionAgent,
            hasOrderManager: false,
            orderManager: model.orderManager,
            hasCustomerManager: false,
            customerManager: model.customerManager
        },
        validationSchema: Yup.object().shape({
            executionAgent: Yup.string().nullable().when('hasExecutionAgent', {
                is: (hasExecutionAgent: boolean) => hasExecutionAgent,
                then: () => Yup.string().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("offer.grid.executor"))),
                otherwise: () => Yup.string().nullable()
            }),
            orderManager: Yup.string().nullable().when('hasOrderManager', {
                is: (hasOrderManager: boolean) => hasOrderManager,
                then: () => Yup.string().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("offer.grid.order-manager"))),
                otherwise: () => Yup.string().nullable()
            }),
            customerManager: Yup.string().nullable().when('hasCustomerManager', {
                is: (hasCustomerManager: boolean) => hasCustomerManager,
                then: () => Yup.string().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("offer.grid.customer-manager"))),
                otherwise: () => Yup.string().nullable()
            })
        }),
        onSubmit: async (values: IAssignOfferPostAction, actions: FormikHelpers<IAssignOfferPostAction>) => {
            actions.setSubmitting(true);

            await save(values);

            actions.setSubmitting(false);
        }
    });

    return (
        <form id='assign-offer-form'
              onSubmit={form.handleSubmit}>
            <SelectedOffers ids={model.items.map(item => item.id)}/>
            <div className={'pd-10'}>
                <div className={'mgb-10'}>
                    <div style={label}>
                        <Checkbox checked={form.values.hasExecutionAgent}
                                  id={'assign-offer-has-execution-agent'}
                                  change={(val) => {
                                      setIsSubmitDisabled(!(form.values.hasCustomerManager || form.values.hasOrderManager || val))

                                      form.setFieldValue('hasExecutionAgent', val)

                                      if (!val) {
                                          form.setFieldValue('executionAgent', null);
                                      }
                                  }}
                                  disabled={disabled || form.isSubmitting}/>
                        <label className={`view-label ${form.values.hasExecutionAgent ? 'required' : ''}`}>
                            {t("offer.grid.executor")}
                        </label>
                    </div>
                    <AssignOfferFormManagerSelector id={'assign-offer-execution-agent'}
                                                    placeholder={t("offer.grid.executor")}
                                                    disabled={disabled || form.isSubmitting || !form.values.hasExecutionAgent}
                                                    onChange={(val) => form.setFieldValue('executionAgent', val)}
                                                    value={form.values.executionAgent}/>
                    {form.values.hasExecutionAgent && form.errors.executionAgent &&
                        <div className={'error-label'}>
                            {form.errors.executionAgent}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <div style={label}>
                        <Checkbox checked={form.values.hasOrderManager}
                                  id={'assign-offer-has-order-manager'}
                                  change={(val) => {
                                      setIsSubmitDisabled(!(form.values.hasCustomerManager || form.values.hasExecutionAgent || val))

                                      form.setFieldValue('hasOrderManager', val)

                                      if (!val) {
                                          form.setFieldValue('orderManager', null);
                                      }
                                  }}
                                  disabled={disabled || form.isSubmitting}/>
                        <label className={`view-label ${form.values.hasOrderManager ? 'required' : ''}`}>
                            {t("offer.grid.order-manager")}
                        </label>
                    </div>
                    <AssignOfferFormManagerSelector id={'assign-offer-order-manager'}
                                                    placeholder={t("offer.grid.order-manager")}
                                                    disabled={disabled || form.isSubmitting || !form.values.hasOrderManager}
                                                    onChange={(val) => form.setFieldValue('orderManager', val)}
                                                    value={form.values.orderManager}/>
                    {form.values.hasOrderManager && form.errors.orderManager &&
                        <div className={'error-label'}>
                            {form.errors.orderManager}
                        </div>
                    }
                </div>

                <div className={'mgb-10'}>
                    <div style={label}>
                        <Checkbox checked={form.values.hasCustomerManager}
                                  id={'assign-offer-has-customer-agent'}
                                  change={(val) => {
                                      setIsSubmitDisabled(!(form.values.hasOrderManager || form.values.hasExecutionAgent || val))

                                      form.setFieldValue('hasCustomerManager', val)

                                      if (!val) {
                                          form.setFieldValue('customerManager', null);
                                      }
                                  }}
                                  disabled={disabled || form.isSubmitting}/>
                        <label className={`view-label ${form.values.hasCustomerManager ? 'required' : ''}`}>
                            {t("offer.grid.customer-manager")}
                        </label>
                    </div>
                    <AssignOfferFormManagerSelector id={'assign-offer-customer-manager'}
                                                    placeholder={t("offer.grid.customer-manager")}
                                                    disabled={disabled || form.isSubmitting || !form.values.hasCustomerManager}
                                                    onChange={(val) => form.setFieldValue('customerManager', val)}
                                                    value={form.values.customerManager}/>
                    {form.values.hasCustomerManager && form.errors.customerManager &&
                        <div className={'error-label'}>
                            {form.errors.customerManager}
                        </div>
                    }
                </div>
            </div>
        </form>
    );
};

export default AssignOfferForm;