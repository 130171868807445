import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Dialog from "../../../../../components/ui/Dialog/Dialog";
import {useOfferApi} from "../../../../../app/api/offer";
import {ResponseResultCode} from "../../../../../app/enums/ResponseResultCode";
import {getCookie, setCookie} from "../../../../../helpers/cookie";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {setSelectedPredefinedFilter} from "../../../../../features/filter/filterSlice";
import {LayoutConfigEntity} from "../../../../../app/enums/LayoutConfigEntity";
import {useNavigate} from "react-router-dom";
import {selectCurrentUser} from "../../../../../features/authentication/authenticationSliceSelectors";
import {LayoutConfigType} from "../../../../../app/enums/LayoutConfigType";
import {IOfferHeaderConfig} from "../../grid/data/interfaces/IOfferHeaderConfig";
import {useLayoutConfigApi} from "../../../../../app/api/layoutConfig";

const FreeStockOffersDialogAlert: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(selectCurrentUser);

    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);

    const {
        getFreeStockOffersCount: {
            query,
            cancel
        }
    } = useOfferApi();

    const {
        getLayoutConfig: {
            query: getLayoutConfig
        },
        saveLayoutConfig: {
            mutation: saveHeaderConfig
        }
    } = useLayoutConfigApi();

    useEffect(() => {
        if (getCookie('fsa') !== null) {
            return;
        }

        const timeout: NodeJS.Timeout = setTimeout(async () => {
            try {
                const response = await query();

                if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok && response.data.data !== undefined) {
                    setCookie('fsa', 'true', 1);

                    if (response.data.data > 0) {
                        setCount(response.data.data);

                        setIsDialogShown(prev => !prev)
                    }
                }
            } catch {
                // ignore
            }
        }, 2000);

        return () => {
            cancel();
            clearTimeout(timeout);
        }
    }, []);

    const clearPredefinedFilter = async () => {
        if (!user) {
            return;
        }

        try {
            const response = await getLayoutConfig(
                LayoutConfigEntity.Offer,
                LayoutConfigType.GridActionRow,
                user.deviceId);

            if (response?.status === 200 && response.data?.resultCode === ResponseResultCode.Ok &&
                response.data.data && response.data.data.value) {
                let cv = JSON.parse(response.data.data.value) as IOfferHeaderConfig;

                if ((cv.pinnedPredefinedFilter !== null && cv.pinnedPredefinedFilter !== '')
                    || cv.subTab != (user.isHead || user.isOfficeHeader ? 'offer-all-tab' : 'offer-my-tab')) {

                    cv.subTab = user.isHead || user.isOfficeHeader ? 'offer-all-tab' : 'offer-my-tab';

                    await saveHeaderConfig({
                        ...response.data.data,
                        deviceId: user.deviceId,
                        value: JSON.stringify({
                            ...cv,
                            pinnedPredefinedFilter: null
                        } as IOfferHeaderConfig)
                    });
                }
            }
        } catch {
            // ignore
        }
    };

    return (
        <Dialog id={'free-stock-offers-count-alert'}
                show={isDialogShown}
                setShow={setIsDialogShown}
                width={'750px'}
                title={t("sidebar.offer.exchange")}>
            <div className={'h100 w100'} style={{padding: '20px'}}>
                <div style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '50px',
                    paddingBottom: '50px',
                    color: '#4a4a4a',
                    fontSize: '20px'
                }}>
                    {t("offer.form.new-free-stock-offer-alert-label").replace('{0}', count.toString())}
                </div>

                <div style={{textAlign: 'center'}}>
                    <Button buttonType={ButtonType.Primary}
                            style={{fontSize: '17px', minWidth: '150px'}}
                            onClick={async () => {
                                await clearPredefinedFilter();

                                dispatch(setSelectedPredefinedFilter({
                                    entity: LayoutConfigEntity.Offer,
                                    data: null
                                }));

                                navigate('/offers/exchange');

                                setIsDialogShown(prev => !prev);
                            }}>
                        {t("shared.btn.show-all")}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default FreeStockOffersDialogAlert;