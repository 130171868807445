import React from 'react';
import {IOffer} from "../../../../../../app/interfaces/offer/IOffer";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../../../../../hooks/useMediaQuery/useMediaQuery";
import {UserRole} from "../../../../../../app/enums/UserRole";
import {getUserRole} from "../../../../../../helpers/user";
import OfferNotes from "./fields/OfferNotes/OfferNotes";
import OfferForwardingAgent from "./fields/OfferForwardingAgent/OfferForwardingAgent";
import OfferOrderManager from "./fields/OfferOrderManager/OfferOrderManager";
import OfferOfferGid from "./fields/OfferOfferGid/OfferOfferGid";
import OfferForwarder from "./fields/OfferForwarder/OfferForwarder";
import OfferCustomerManager from "./fields/OfferCustomerManager/OfferCustomerManager";
import OfferAvailableTill from "./fields/OfferAvailableTill/OfferAvailableTill";
import OfferInitialBet from "./fields/OfferInitialBet/OfferInitialBet";
import OfferSupplier from "./fields/OfferSupplier/OfferSupplier";
import OfferCustomer from "./fields/OfferCustomer/OfferCustomer";
import OfferCostBrutto from "./fields/OfferCostBrutto/OfferCostBrutto";
import OfferTransporeonNumber from "./fields/OfferTransporeonNumber/OfferTransporeonNumber";
import OfferCustomerOrderRefNumber from "./fields/OfferCustomerOrderRefNumber/OfferCustomerOrderRefNumber";
import OfferTransporeonTransportNumber from "./fields/OfferTransporeonTransportNumber/OfferTransporeonTransportNumber";
import OfferTransporeonBookingData from "./fields/OfferTransporeonBookingData/OfferTransporeonBookingData";
import OfferTransEu from "./fields/OfferTransEU/OfferTransEU";
import TagFormField from "../../../../../../components/shared/components/Tag/components/TagFormField/TagFormField";
import {OrderType} from "../../../../../../app/enums/OrderType";
import moment from "moment";
import DetailsBoxColumn from '../../../../../../components/ui/DetailsBox/components/DetailsBoxColumn/DetailsBoxColumn';
import DetailsBox from "../../../../../../components/ui/DetailsBox/DetailsBox";
import {IUser} from "../../../../../../app/interfaces/user/IUser";
import CustomerAttachment from "./fields/CustomerAttachment/CustomerAttachment";
import { ICustomerAttachmentDoc } from '../../../../../../app/interfaces/customerAttachment/ICustomerAttachmentDoc';

type OfferDetailsBoxProps = {
    offer: IOffer;
    isSidePanelDetails: boolean;
    user?: IUser;
    customerAttachmentDocs: Array<ICustomerAttachmentDoc> | null;
};

const OfferDetailsBox: React.FC<OfferDetailsBoxProps> = ({
                                                             isSidePanelDetails,
                                                             offer,
                                                             user,
                                                             customerAttachmentDocs
                                                         }) => {
    const {t} = useTranslation();
    const isMaxWidth960 = useMediaQuery('(max-width: 960px)');
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');

    const role = user ? getUserRole(user) : null;

    return (
        <DetailsBox id={'offer-details-data-box'} isSidePanelDetails={isSidePanelDetails || isMaxWidth960}>
            <DetailsBoxColumn>
                <div className={'details-box-default-container'}>


                    {!isMaxWidth600 && !isSidePanelDetails &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'2'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-release-gid'}>
                                    <label title={t("offer.form.order-release-gid")}>
                                        {t("offer.form.order-release-gid")}
                                    </label>
                                    <div>
                                        <OfferOfferGid offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'} id={'details-box-forwarder'}>
                                    <label title={t("offer.grid.executor")}>
                                        {role === null || role === UserRole.ServiceProvider
                                            ? <>{t("offer.grid.forwarderAsstrA")}</>
                                            : <>{t("offer.grid.executor")}</>
                                        }
                                    </label>
                                    <div>
                                        <OfferForwarder offer={offer}/>
                                    </div>
                                </div>

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-forwarder-agent'}>
                                        <label title={t("offer.grid.forwarder-agent")}>
                                            {t("offer.grid.forwarder-agent")}
                                        </label>
                                        <div>
                                            <OfferForwardingAgent offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-customer-manager'}>
                                        <label title={t("offer.grid.customer-manager")}>
                                            {t("offer.grid.customer-manager")}
                                        </label>
                                        <div>
                                            <OfferCustomerManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-order-manager'}>
                                        <label title={t("offer.grid.order-manager")}>
                                            {t("offer.grid.order-manager")}
                                        </label>
                                        <div>
                                            <OfferOrderManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {offer.isAuction && offer.spUser && offer.spUser.name !== '' &&
                                    <div className={'details-box-default-container-item'}
                                         id={'details-box-supplier'}>
                                        <label title={t("offer.form.supplier")}>
                                            {t("offer.form.supplier")}
                                        </label>
                                        <div>
                                            <OfferSupplier offer={offer}/>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-available-till'}>
                                    <label title={t("offer.form.available-till")}>
                                        {t("offer.form.available-till")}
                                    </label>
                                    <div>
                                        <OfferAvailableTill offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-initial-bet'}>
                                    <label title={t("offer.grid.auction-bet")}>
                                        {t("offer.grid.auction-bet")}
                                    </label>
                                    <div>
                                        <OfferInitialBet offer={offer}/>
                                    </div>
                                </div>


                            </div>
                        </div>
                    }

                    {!isMaxWidth600 && !isSidePanelDetails && role !== null && role !== UserRole.ServiceProvider &&
                        <div>
                            <TagFormField orderReleaseGid={offer.orderReleaseGid}
                                          orderType={OrderType.OrderRelease}
                                          tags={offer.tags}/>
                        </div>
                    }

                    {!isMaxWidth600 && isSidePanelDetails &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'2'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-release-gid'}>
                                    <label title={t("offer.form.order-release-gid")}>
                                        {t("offer.form.order-release-gid")}
                                    </label>
                                    <div>
                                        <OfferOfferGid offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'} id={'details-box-forwarder'}>
                                    <label title={t("offer.grid.executor")}>
                                        {role === null || role === UserRole.ServiceProvider
                                            ? <>{t("offer.grid.forwarderAsstrA")}</>
                                            : <>{t("offer.grid.executor")}</>
                                        }
                                    </label>
                                    <div>
                                        <OfferForwarder offer={offer}/>
                                    </div>
                                </div>

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-forwarder-agent'}>
                                        <label title={t("offer.grid.forwarder-agent")}>
                                            {t("offer.grid.forwarder-agent")}
                                        </label>
                                        <div>
                                            <OfferForwardingAgent offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-customer-manager'}>
                                        <label title={t("offer.grid.customer-manager")}>
                                            {t("offer.grid.customer-manager")}
                                        </label>
                                        <div>
                                            <OfferCustomerManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-order-manager'}>
                                        <label title={t("offer.grid.order-manager")}>
                                            {t("offer.grid.order-manager")}
                                        </label>
                                        <div>
                                            <OfferOrderManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {offer.isAuction && offer.spUser && offer.spUser.name !== '' &&
                                    <div className={'details-box-default-container-item'}
                                         id={'details-box-supplier'}>
                                        <label title={t("offer.form.supplier")}>
                                            {t("offer.form.supplier")}
                                        </label>
                                        <div>
                                            <OfferSupplier offer={offer}/>
                                        </div>
                                    </div>
                                }
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-available-till'}>
                                    <label title={t("offer.form.available-till")}>
                                        {t("offer.form.available-till")}
                                    </label>
                                    <div>
                                        <OfferAvailableTill offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-initial-bet'}>
                                    <label title={t("offer.grid.auction-bet")}>
                                        {t("offer.grid.auction-bet")}
                                    </label>
                                    <div>
                                        <OfferInitialBet offer={offer}/>
                                    </div>
                                </div>
                                {role !== null && role !== UserRole.ServiceProvider &&
                                    <div>
                                        <TagFormField orderReleaseGid={offer.orderReleaseGid}
                                                      orderType={OrderType.OrderRelease}
                                                      tags={offer.tags}/>
                                    </div>
                                }
                            </div>

                            <div>

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-customer'}>
                                        <label title={t("offer.grid.customer")}>
                                            {t("offer.grid.customer")}
                                        </label>
                                        <div>
                                            <OfferCustomer offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-cost-brutto'}>
                                        <label title={t("offer.grid.cost-brutto")}>
                                            {t("offer.grid.cost-brutto")}
                                        </label>
                                        <div>
                                            <OfferCostBrutto offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-transporeon-id'}>
                                        <label title={t("offer.form.transporeon-id")}>
                                            {t("offer.form.transporeon-id")}
                                        </label>
                                        <div>
                                            <OfferTransporeonNumber offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-ref-num'}>
                                        <label title={t("offer.grid.ref-num-label")}>
                                            {t("offer.grid.ref-num-label")}
                                        </label>
                                        <div>
                                            <OfferCustomerOrderRefNumber offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-transporeon-transport-number'}>
                                        <label title={t("offer.form.transporeon-number")}>
                                            {t("offer.form.transporeon-number")}
                                        </label>
                                        <div>
                                            <OfferTransporeonTransportNumber offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }
                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                && (offer.transporeonBookingData)
                                    ? <div className={'col-sm'}
                                           style={{
                                               display: 'flex',
                                               justifyContent: 'end',
                                               alignItems: 'center',
                                               gap: '5px'
                                           }}>
                                        <OfferTransporeonBookingData offer={offer}/>
                                        <OfferTransEu offer={offer}/>
                                    </div>
                                    : null
                                }
                                {role !== null && role !== UserRole.ServiceProvider &&
                                    <>
                                        <div>
                                            <OfferNotes notes={offer.notes}
                                                        notesInfo={offer.notesUser + " " + moment.utc(offer.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                                                        orderReleaseGid={offer.orderReleaseGid ?? ''}/>
                                        </div>
                                    </>
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div>                                
                                            <CustomerAttachment orderReleaseGid={offer.orderReleaseGid ?? ''}
                                                    customerAttachmentDocs={customerAttachmentDocs}/>
                                        </div>
                                        : null
                                    }
                            </div>
                        </div>
                    }

                    {isMaxWidth600 &&
                        <div className={'details-box-default-container-columns'}
                             data-count={'1'}>
                            <div>
                                <div className={'details-box-default-container-item'}
                                     id={'details-box-release-gid'}>
                                    <label title={t("offer.form.order-release-gid")}>
                                        {t("offer.form.order-release-gid")}
                                    </label>
                                    <div>
                                        <OfferOfferGid offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'} id={'details-box-forwarder'}>
                                    <label title={t("offer.grid.executor")}>
                                        {role === null || role === UserRole.ServiceProvider
                                            ? <>{t("offer.grid.forwarderAsstrA")}</>
                                            : <>{t("offer.grid.executor")}</>
                                        }
                                    </label>
                                    <div>
                                        <OfferForwarder offer={offer}/>
                                    </div>
                                </div>

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-forwarder-agent'}>
                                        <label title={t("offer.grid.forwarder-agent")}>
                                            {t("offer.grid.forwarder-agent")}
                                        </label>
                                        <div>
                                            <OfferForwardingAgent offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-customer-manager'}>
                                        <label title={t("offer.grid.customer-manager")}>
                                            {t("offer.grid.customer-manager")}
                                        </label>
                                        <div>
                                            <OfferCustomerManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'}
                                           id={'details-box-order-manager'}>
                                        <label title={t("offer.grid.order-manager")}>
                                            {t("offer.grid.order-manager")}
                                        </label>
                                        <div>
                                            <OfferOrderManager offer={offer}/>
                                        </div>
                                    </div>
                                    : null
                                }

                                {offer.isAuction && offer.spUser && offer.spUser.name !== '' &&
                                    <div className={'details-box-default-container-item'}
                                         id={'details-box-supplier'}>
                                        <label title={t("offer.form.supplier")}>
                                            {t("offer.form.supplier")}
                                        </label>
                                        <div>
                                            <OfferSupplier offer={offer}/>
                                        </div>
                                    </div>
                                }

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-available-till'}>
                                    <label title={t("offer.form.available-till")}>
                                        {t("offer.form.available-till")}
                                    </label>
                                    <div>
                                        <OfferAvailableTill offer={offer}/>
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                     id={'details-box-initial-bet'}>
                                    <label title={t("offer.grid.auction-bet")}>
                                        {t("offer.grid.auction-bet")}
                                    </label>
                                    <div>
                                        <OfferInitialBet offer={offer}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </DetailsBoxColumn>

            {!isMaxWidth600 && !isSidePanelDetails &&
                <DetailsBoxColumn>
                    <div className={'details-box-default-container'}>
                        {!isMaxWidth600 && !isSidePanelDetails &&
                            <div className={'details-box-default-container-columns'}
                                 data-count={'2'}>
                                <div>
                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-customer'}>
                                            <label title={t("offer.grid.customer")}>
                                                {t("offer.grid.customer")}
                                            </label>
                                            <div>
                                                <OfferCustomer offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-cost-brutto'}>
                                            <label title={t("offer.grid.cost-brutto")}>
                                                {t("offer.grid.cost-brutto")}
                                            </label>
                                            <div>
                                                <OfferCostBrutto offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-transporeon-id'}>
                                            <label title={t("offer.form.transporeon-id")}>
                                                {t("offer.form.transporeon-id")}
                                            </label>
                                            <div>
                                                <OfferTransporeonNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-ref-num'}>
                                            <label title={t("offer.grid.ref-num-label")}>
                                                {t("offer.grid.ref-num-label")}
                                            </label>
                                            <div>
                                                <OfferCustomerOrderRefNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-transporeon-transport-number'}>
                                            <label title={t("offer.form.transporeon-number")}>
                                                {t("offer.form.transporeon-number")}
                                            </label>
                                            <div>
                                                <OfferTransporeonTransportNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    && (offer.transporeonBookingData)
                                        ? <div className={'col-sm'}
                                               style={{
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   gap: '5px'
                                               }}>
                                            <OfferTransporeonBookingData offer={offer}/>
                                            <OfferTransEu offer={offer}/>
                                        </div>
                                        : null
                                    }

                                    {!isMaxWidth600 && !isSidePanelDetails && role !== null && role !== UserRole.ServiceProvider &&
                                        <div>
                                            <OfferNotes notes={offer.notes}
                                                        notesInfo={offer.notesUser + " " + moment.utc(offer.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                                                        orderReleaseGid={offer.orderReleaseGid ?? ''}/>
            
                                        </div>
                                    }
                                </div>
                                <div>

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div>
                                            <CustomerAttachment orderReleaseGid={offer.orderReleaseGid ?? ''}
                                                    customerAttachmentDocs={customerAttachmentDocs}/>
                                        </div>
                                        : null
                                    }

                                </div>
                            </div>
                        }


                        {isMaxWidth600 &&
                            <div className={'details-box-default-container-columns'}
                                 data-count={'1'}>
                                <div>


                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-customer'}>
                                            <label title={t("offer.grid.customer")}>
                                                {t("offer.grid.customer")}
                                            </label>
                                            <div>
                                                <OfferCustomer offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-cost-brutto'}>
                                            <label title={t("offer.grid.cost-brutto")}>
                                                {t("offer.grid.cost-brutto")}
                                            </label>
                                            <div>
                                                <OfferCostBrutto offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-transporeon-id'}>
                                            <label title={t("offer.form.transporeon-id")}>
                                                {t("offer.form.transporeon-id")}
                                            </label>
                                            <div>
                                                <OfferTransporeonNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-ref-num'}>
                                            <label title={t("offer.grid.ref-num-label")}>
                                                {t("offer.grid.ref-num-label")}
                                            </label>
                                            <div>
                                                <OfferCustomerOrderRefNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                        ? <div className={'details-box-default-container-item'}
                                               id={'details-box-transporeon-transport-number'}>
                                            <label title={t("offer.form.transporeon-number")}>
                                                {t("offer.form.transporeon-number")}
                                            </label>
                                            <div>
                                                <OfferTransporeonTransportNumber offer={offer}/>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {role !== null && (role === UserRole.Forwarder || role === UserRole.Administrator)
                                    && (offer.transporeonBookingData)
                                        ? <div className={'col-sm'}
                                               style={{
                                                   display: 'flex',
                                                   justifyContent: 'end',
                                                   alignItems: 'center',
                                                   gap: '5px'
                                               }}>
                                            <OfferTransporeonBookingData offer={offer}/>
                                            <OfferTransEu offer={offer}/>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        }


                        
                    </div>
                </DetailsBoxColumn>
            }
        </DetailsBox>
    );
};

export default OfferDetailsBox;
