import React, {useCallback, useContext, useEffect} from 'react';
import {ExtendedNettoBet, QuoteNettoBetListContext} from "../../../QuoteNettoBetList";
import {FormikHelpers, useFormik} from "formik";
import * as Yup from "yup";
import {INettoBetPostAction} from "../../../../../../../../../app/interfaces/quote/INettoBetPostAction";
import cl from './QuoteNettoBetListItemForm.module.css';
import NettoBetEquipmentGroupSelector
    from "../components/NettoBetEquipmentGroupSelector/NettoBetEquipmentGroupSelector";
import Input from "../../../../../../../../../components/ui/Input/Input";
import {useTranslation} from "react-i18next";
import NettoBetServiceProviderSelector
    from "../components/NettoBetServiceProviderSelector/NettoBetServiceProviderSelector";
import NettoBetContractSelector from "../components/NettoBetContractSelector/NettoBetContractSelector";
import NettoBetCurrencySelector from "../components/NettoBetCurrencySelector/NettoBetCurrencySelector";
import {NumericTextBox} from '@progress/kendo-react-inputs';
import Checkbox from "../../../../../../../../../components/ui/Checkbox/Checkbox";
import Textarea from "../../../../../../../../../components/ui/Textarea/Textarea";
import NettoBetDefaultCurrencySelector from "../components/NettoBetCurrencySelector/NettoBetDefaultCurrencySelector";
import NettoBetAsstraCompanySelector from "../components/NettoBetAsstraCompanySelector/NettoBetAsstraCompanySelector";
import {faClock} from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import {debounce} from "debounce";
import {useQuoteApi} from "../../../../../../../../../app/api/quote";
import NettoBetBetList from "../components/NettoBetBetList/NettoBetBetList";
import {IQuote} from "../../../../../../../../../app/interfaces/quote/IQuote";
import {parseMoney} from "../../../../../../../../../helpers/currency";
import ReactDatePicker from "../../../../../../../../../components/ui/ReactDatePicker/ReactDatePicker/ReactDatePicker";
import ReactTimePicker from "../../../../../../../../../components/ui/ReactDatePicker/ReactTimePicker/ReactTimePicker";

type QuoteNettoBetListItemFormProps = {
    nettoBet: ExtendedNettoBet;
    disabled: boolean;
    resetNumber: number;
    quote: IQuote;
}

const textareaStyle: React.CSSProperties = {
    resize: 'vertical',
    minHeight: '100px',
    maxHeight: '250px',
    marginTop: '5px'
}

const QuoteNettoBetListItemForm: React.FC<QuoteNettoBetListItemFormProps> = ({
                                                                                 nettoBet,
                                                                                 disabled,
                                                                                 resetNumber,
                                                                                 quote
                                                                             }) => {
    const {t} = useTranslation();
    const {
        getProfit: {
            query: getProfit
        }
    } = useQuoteApi();

    const {
        saveNettoBet
    } = useContext(QuoteNettoBetListContext);

    useEffect(() => {
        form.resetForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetNumber]);

    const form = useFormik<INettoBetPostAction>({
        initialValues: {
            id: nettoBet.id,
            eqGroup: nettoBet.eqGroup,
            eqType: nettoBet.eqType,
            eqEdit: nettoBet.eqEdit,
            serviceProviderIdNb: nettoBet.serviceProviderIdNb,
            serviceProviderNameNb: nettoBet.serviceProviderNameNb,
            contractId: nettoBet.contractId,
            bet: nettoBet.bet,
            isTransporeon: nettoBet.isTransporeon,
            currencyCode: nettoBet.currencyCode,
            hasDemurrageNotes: nettoBet.hasDemurrageNotes,
            demurrageNotes: nettoBet.demurrageNotes,
            hasTransitCountries: nettoBet.hasTransitCountries,
            transitCountries: nettoBet.transitCountries,
            hasBorderCrossingPoints: nettoBet.hasBorderCrossingPoints,
            borderCrossingPoints: nettoBet.borderCrossingPoints,
            notes: nettoBet.notes,
            additionalComments: nettoBet.additionalComments,
            hasPayableWeight: nettoBet.hasPayableWeight,
            payableWeight: nettoBet.payableWeight,
            hasRateExcludes: nettoBet.hasRateExcludes,
            rateExcludes: nettoBet.rateExcludes,
            hasRateIncludes: nettoBet.hasRateIncludes,
            rateIncludes: nettoBet.rateIncludes,
            sellRateCurrencyCode: nettoBet.sellRateCurrencyCode,
            minRateCurrencyCode: nettoBet.minRateCurrencyCode,
            sellRate: nettoBet.sellRate,
            minRate: nettoBet.minRate,
            profit: nettoBet.profit,
            profitCurrencyCode: nettoBet.profitCurrencyCode,
            asstraCompany: nettoBet.asstraCompany,
            demurrage: nettoBet.demurrage,
            demurrageCurrencyCode: nettoBet.demurrageCurrencyCode,
            unloadingClearanceTime: nettoBet.unloadingClearanceTime,
            loadingClearanceTime: nettoBet.loadingClearanceTime,
            preavis: nettoBet.preavis,
            transitTime: nettoBet.transitTime,
            tillDate: nettoBet.tillDate,
            tillDateTime: nettoBet.tillDateTime,
            orderReleaseXid: nettoBet.orderReleaseXid
        },
        validationSchema: Yup.object().shape({
            eqGroup: Yup.string().nullable().when('eqEdit', {
                is: (eqEdit: boolean) => eqEdit,
                then: () => Yup.string().nullable().required(t("shared.errors.required-error")
                    .replace('{0}', t("quote.bet.equipment-group"))),
                otherwise: () => Yup.string().nullable()
            }),
            asstraCompany: Yup.string().nullable().when('isTransporeon', {
                is: (isTransporeon: boolean) => isTransporeon,
                then: () => Yup.string().nullable()
                    .required(t("shared.errors.required-error")
                        .replace('{0}', t("quote.bet.asstra-company"))),
                otherwise: () => Yup.string().nullable()
            }),
            bet: Yup.string().nullable().when('isTransporeon', {
                is: (isTransporeon: boolean) => !isTransporeon,
                then: () => Yup.string().nullable()
                    .min(0)
                    .max(1000000)
                    .required(t("shared.errors.required-error")
                        .replace('{0}', t("quote.bet.netto-bet"))),
                otherwise: () => Yup.string().nullable()
                    .min(0)
                    .max(1000000)
            }),
            tillDate: Yup.string().nullable()
                .required(t("shared.errors.required-error")
                    .replace('{0}', t("quote.bet.till-date-time"))),
            sellRate: Yup.string().nullable()
                .min(0)
                .max(1000000)
                .required(t("shared.errors.required-error").replace('{0}', t("quote.bet.sell-rate")))
        }),
        onSubmit: async (values: INettoBetPostAction, actions: FormikHelpers<INettoBetPostAction>) => {
            actions.setSubmitting(true);

            await saveNettoBet(values, nettoBet);

            actions.setSubmitting(false);
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateProfitValue = useCallback(debounce(async (netto: string | null, brutto: string | null, nettoCurrencyCode: string | null, bruttoCurrencyCode: string | null) => {
        if (!netto || !brutto || !nettoCurrencyCode || !bruttoCurrencyCode) {
            await form.setFieldValue('profit', null);

            return;
        }

        try {
            const response = await getProfit(netto, brutto, nettoCurrencyCode, bruttoCurrencyCode);

            if (response?.status === 200 && response.data?.data) {
                await form.setFieldValue('profit', response.data.data.value);
                await form.setFieldValue('profitCurrencyCode', response.data.data.currency);
            }
        } catch {
            //ignore
        }
    }, 500), []);

    return (
        <form id={`netto-bet-form-${nettoBet.uuid}`}
              className={'netto-bet-form'}
              onSubmit={form.handleSubmit}>
            {!nettoBet.isTransporeon && nettoBet.eqEdit &&
                <div className={`view-row drop-down-picker mgb-5`}>
                    <div className={cl.row}>
                        <div className={'col-sm-2'}>
                            <label className={'view-label required'}>
                                {t("quote.bet.equipment-group")}
                            </label>
                            <NettoBetEquipmentGroupSelector disabled={disabled || !nettoBet.isEditMode}
                                                            equipment={form.values.eqGroup}
                                                            onChange={(eqGroup, eqAlias) => {
                                                                form.setFieldValue('eqType', eqAlias);
                                                                form.setFieldValue('eqGroup', eqGroup);
                                                            }}/>
                        </div>
                        <div className={'col-sm-2'}>
                            <label className={'view-label required'}>
                                {t("quote.bet.transport-equipment")}
                            </label>
                            <Input disabled={true}
                                   autoComplete={'off'}
                                   name={'eqType'}
                                   id={'eqType'}
                                // placeholder={t("quote.bet.transport-equipment")}
                                   value={form.values.eqType ?? ''}
                                   onChange={form.handleChange}
                            />
                        </div>
                    </div>
                    {form.errors.eqGroup &&
                        <div className={'error-label'}>
                            {form.errors.eqGroup}
                        </div>
                    }
                </div>
            }


            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2 combobox-picker'}>
                        <label className={'view-label'}>
                            {t("quote.bet.service-provider")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <NettoBetServiceProviderSelector disabled={disabled || !nettoBet.isEditMode}
                                                             value={form.values.serviceProviderIdNb ?? undefined}
                                                             text={form.values.serviceProviderNameNb ?? undefined}
                                                             onChange={(val, text) => {
                                                                 form.setFieldValue('serviceProviderIdNb', val);
                                                                 form.setFieldValue('serviceProviderNameNb', text);
                                                             }}/>
                        </div>


                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label required'}>
                            {t("quote.bet.netto-bet")}
                        </label>
                        <div>
                            <div className={cl.twoColumnRow}>
                                <div className={'col-sm-2 numeric-text-box'} style={{width: '60%'}}>
                                    <NumericTextBox disabled={disabled || !nettoBet.isEditMode}
                                        // placeholder={t("quote.bet.netto-bet")}
                                                    spinners={false}
                                                    name={'bet'}
                                                    format={'n2'}
                                                    value={form.values.bet && form.values.bet !== '' ? parseMoney(form.values.bet) : null}
                                                    min={0}
                                                    max={100000000}
                                                    onChange={async (ev) => {
                                                        if (ev.value === null || ev.value === 0) {
                                                            await updateProfitValue(
                                                                null,
                                                                form.values.sellRate,
                                                                form.values.currencyCode,
                                                                form.values.sellRateCurrencyCode);

                                                            await form.setFieldValue('bet', null);

                                                            return;
                                                        }

                                                        await updateProfitValue(
                                                            ev.value?.toString(),
                                                            form.values.sellRate,
                                                            form.values.currencyCode,
                                                            form.values.sellRateCurrencyCode);

                                                        await form.setFieldValue('bet', ev.value?.toString());
                                                    }}
                                    />
                                </div>
                                <div className={'col-sm-2 drop-down-picker'} style={{width: '40%'}}>
                                    <NettoBetCurrencySelector disabled={disabled || !nettoBet.isEditMode}
                                                              currency={form.values.currencyCode}
                                                              onChange={(value: string | null) => {
                                                                  updateProfitValue(
                                                                      form.values.bet,
                                                                      form.values.sellRate,
                                                                      value,
                                                                      form.values.sellRateCurrencyCode).then(() => {
                                                                      //ignore
                                                                  });

                                                                  form.setFieldValue('currencyCode', value)
                                                              }}/>
                                </div>
                            </div>

                        </div>
                        {form.errors.bet &&
                            <div className={'error-label'}>
                                {form.errors.bet}
                            </div>
                        }
                    </div>
                </div>
            }


            {!nettoBet.isTransporeon &&
                <div className={`view-row combobox-picker mgb-5`}>
                    <label className={'view-label'}>
                        {t("quote.bet.contract")}
                    </label>
                    <NettoBetContractSelector disabled={disabled || !nettoBet.isEditMode}
                                              value={form.values.contractId ?? undefined}
                                              spName={form.values.serviceProviderNameNb}
                                              spId={form.values.serviceProviderIdNb}
                                              orderReleaseGid={`AAG.${nettoBet.orderReleaseXid}`}
                                              onChange={(val) => {
                                                  form.setFieldValue('contractId', val);
                                              }}/>
                </div>
            }

            <NettoBetBetList nettoBet={nettoBet}
                             quote={quote}
                             onSelect={(data) => {
                                 form.setFieldValue('bet', data.valueBase.toString());
                                 form.setFieldValue('currencyCode', data.currencyCode);

                                 if (!data.isPredictedBet) {
                                     form.setFieldValue('serviceProviderIdNb', data.serviceProviderId);
                                     form.setFieldValue('serviceProviderNameNb', data.serviceProvider);
                                 } else {
                                     form.setFieldValue('serviceProviderIdNb', null);
                                     form.setFieldValue('serviceProviderNameNb', null);
                                 }
                             }}/>

            {/*{form.values.sellRateCurrencyCode &&*/}
            {/*      <NettoBetPredictSection disabled={disabled || !nettoBet.isEditMode}*/}
            {/*                              nettoBet={nettoBet}*/}
            {/*                              bruttoCurrency={form.values.sellRateCurrencyCode}/>*/}
            {/*  }*/}

            {nettoBet.isTransporeon &&
                <div className={'view-row mgb-5 combobox-picker'}>
                    <label className={'view-label required'}>
                        {t("quote.bet.asstra-company")}
                    </label>
                    <NettoBetAsstraCompanySelector disabled={disabled || !nettoBet.isEditMode}
                                                   value={form.values.asstraCompany ?? undefined}
                                                   orderReleaseGid={`AAG.${nettoBet.orderReleaseXid}`}
                                                   onChange={(val) => {
                                                       form.setFieldValue('asstraCompany', val);
                                                   }}/>
                    {form.errors.asstraCompany &&
                        <div className={'error-label'}>
                            {form.errors.asstraCompany}
                        </div>
                    }
                </div>
            }

            {nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2'}>
                        <label className={'view-label required'}>
                            {t("quote.bet.till-date-time")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <div className={'picker'} style={{width: '100%'}}>
                                <ReactDatePicker disabled={disabled || !nettoBet.isEditMode}
                                                 size={'big'}
                                                 value={form.values.tillDate !== null && form.values.tillDate !== ''
                                                     ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                                     : null}
                                                 onChange={(val) => {
                                                     if (val) {
                                                         form.setFieldValue('tillDate',
                                                             moment(val).format('DD.MM.YYYY'));
                                                     } else {
                                                         form.setFieldValue('tillDate', null);
                                                     }
                                                 }}
                                />
                            </div>
                            <div style={{width: '40%'}}>
                                <ReactTimePicker size={'big'}
                                                 disabled={disabled || !nettoBet.isEditMode}
                                                 value={form.values.tillDateTime !== null && form.values.tillDateTime !== ''
                                                     ? moment(form.values.tillDateTime, 'HH:mm').toDate()
                                                     : null}
                                                 onChange={(val) => {
                                                     if (val) {
                                                         form.setFieldValue('tillDateTime',
                                                             moment(val).format('HH:mm'));
                                                     } else {
                                                         form.setFieldValue('tillDateTime', null);
                                                     }
                                                 }}/>
                            </div>
                        </div>
                        {nettoBet.rateValidUntilRequired !== null && nettoBet.rateValidUntilRequired !== '' &&
                            <div className={cl.required}>
                                {`${t("shared.labels.required")} ${nettoBet.rateValidUntilRequired}`}
                            </div>
                        }

                        {form.errors.tillDate &&
                            <div className={'error-label'}>
                                {form.errors.tillDate}
                            </div>
                        }
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label required'}>
                            {t("quote.bet.sell-rate")}
                        </label>
                        <div>
                            <div className={cl.twoColumnRow}>
                                <div className={'numeric-text-box'} style={{width: '65%'}}>
                                    <NumericTextBox disabled={disabled || !nettoBet.isEditMode}
                                        // placeholder={t("quote.bet.sell-rate")}
                                                    spinners={false}
                                                    name={'sellRate'}
                                                    format={'n2'}
                                                    value={form.values.sellRate && form.values.sellRate !== '' ? parseMoney(form.values.sellRate) : null}
                                                    min={0}
                                                    max={100000000}
                                                    onChange={async (ev) => {
                                                        if (ev.value === null || ev.value === 0) {
                                                            await updateProfitValue(
                                                                form.values.bet,
                                                                null,
                                                                form.values.currencyCode,
                                                                form.values.sellRateCurrencyCode);

                                                            await form.setFieldValue('sellRate', null);

                                                            return;
                                                        }

                                                        await updateProfitValue(
                                                            form.values.bet,
                                                            ev.value?.toString(),
                                                            form.values.currencyCode,
                                                            form.values.sellRateCurrencyCode);

                                                        await form.setFieldValue('sellRate', ev.value?.toString());
                                                    }}
                                    />
                                </div>
                                <div className={'drop-down-picker'} style={{width: '35%'}}>
                                    <NettoBetDefaultCurrencySelector disabled={disabled || !nettoBet.isEditMode}
                                                                     currency={form.values.sellRateCurrencyCode}
                                                                     defaultCurrency={nettoBet.defaultCurrency}
                                                                     onChange={(currency) => {
                                                                         updateProfitValue(
                                                                             form.values.bet,
                                                                             form.values.sellRate,
                                                                             form.values.currencyCode,
                                                                             currency).then(() => {
                                                                             //ignore
                                                                         });

                                                                         form.setFieldValue('sellRateCurrencyCode', currency);
                                                                     }}/>
                                </div>
                            </div>
                            {nettoBet.sellRateTarget !== null && nettoBet.sellRateTarget !== '' &&
                                <div className={cl.required}>
                                    {`${t("quote.bet.target-sell-rate")} ${nettoBet.sellRateTarget}`}
                                </div>
                            }

                            {form.errors.sellRate &&
                                <div className={'error-label'}>
                                    {form.errors.sellRate}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.min-rate")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <div className={'numeric-text-box'} style={{width: '65%'}}>
                                <NumericTextBox disabled={disabled || !nettoBet.isEditMode}
                                    // placeholder={t("quote.bet.min-rate")}
                                                spinners={false}
                                                name={'minRate'}
                                                format={'n2'}
                                                value={form.values.minRate && form.values.minRate !== '' ? parseMoney(form.values.minRate) : null}
                                                min={0}
                                                max={100000000}
                                                onChange={(ev) => {
                                                    if (ev.value === null || ev.value === 0) {
                                                        form.setFieldValue('minRate', null);

                                                        return;
                                                    }

                                                    form.setFieldValue('minRate', ev.value?.toString());
                                                }}
                                />
                            </div>
                            <div className={'drop-down-picker'} style={{width: '35%'}}>
                                <NettoBetDefaultCurrencySelector disabled={disabled || !nettoBet.isEditMode}
                                                                 currency={form.values.minRateCurrencyCode}
                                                                 defaultCurrency={nettoBet.defaultCurrency}
                                                                 onChange={(currency) => {
                                                                     form.setFieldValue('minRateCurrencyCode', currency);
                                                                 }}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label required'}>
                            {t("quote.bet.sell-rate")}
                        </label>
                        <div>
                            <div className={cl.twoColumnRow}>
                                <div className={'numeric-text-box'} style={{width: '65%'}}>
                                    <NumericTextBox disabled={disabled || !nettoBet.isEditMode}
                                        //   placeholder={t("quote.bet.sell-rate")}
                                                    spinners={false}
                                                    name={'sellRate'}
                                                    format={'n2'}
                                                    value={form.values.sellRate && form.values.sellRate !== '' ? parseMoney(form.values.sellRate) : null}
                                                    min={0}
                                                    max={100000000}
                                                    onChange={async (ev) => {
                                                        if (ev.value === null || ev.value === 0) {
                                                            await updateProfitValue(
                                                                form.values.bet,
                                                                null,
                                                                form.values.currencyCode,
                                                                form.values.sellRateCurrencyCode);

                                                            await form.setFieldValue('sellRate', null);

                                                            return;
                                                        }

                                                        await updateProfitValue(
                                                            form.values.bet,
                                                            ev.value?.toString(),
                                                            form.values.currencyCode,
                                                            form.values.sellRateCurrencyCode);

                                                        await form.setFieldValue('sellRate', ev.value?.toString());
                                                    }}
                                    />
                                </div>
                                <div className={'drop-down-picker'} style={{width: '35%'}}>
                                    <NettoBetDefaultCurrencySelector disabled={disabled || !nettoBet.isEditMode}
                                                                     currency={form.values.sellRateCurrencyCode}
                                                                     defaultCurrency={nettoBet.defaultCurrency}
                                                                     onChange={(currency) => {
                                                                         updateProfitValue(
                                                                             form.values.bet,
                                                                             form.values.sellRate,
                                                                             form.values.currencyCode,
                                                                             currency).then(() => {
                                                                             //ignore
                                                                         });

                                                                         form.setFieldValue('sellRateCurrencyCode', currency);
                                                                     }}/>
                                </div>
                            </div>
                            {nettoBet.sellRateTarget !== null && nettoBet.sellRateTarget !== '' &&
                                <div className={cl.required}>
                                    {`${t("quote.bet.target-sell-rate")} ${nettoBet.sellRateTarget}`}
                                </div>
                            }

                            {form.errors.sellRate &&
                                <div className={'error-label'}>
                                    {form.errors.sellRate}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2 combobox-picker'}>
                        <label className={'view-label'}>
                            {t("quote.bet.asstra-company")}
                        </label>
                        <NettoBetAsstraCompanySelector disabled={disabled || !nettoBet.isEditMode}
                                                       value={form.values.asstraCompany ?? undefined}
                                                       orderReleaseGid={`AAG.${nettoBet.orderReleaseXid}`}
                                                       onChange={(val) => {
                                                           form.setFieldValue('asstraCompany', val);
                                                       }}/>
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.profit")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <div style={{width: '65%'}}>
                                <Input disabled={true}
                                       autoComplete={'off'}
                                       name={'profit'}
                                       id={'profit'}
                                    //placeholder={t("quote.bet.profit")}
                                       value={form.values.profit ?? ''}
                                       onChange={form.handleChange}
                                />
                            </div>
                            <div className={'drop-down-picker'} style={{width: '35%'}}>
                                <NettoBetDefaultCurrencySelector disabled={true}
                                                                 currency={form.values.profitCurrencyCode}
                                                                 defaultCurrency={'EUR'}
                                                                 onChange={() => {
                                                                     // ignore
                                                                 }}/>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.transit-time")}
                        </label>
                        <Input disabled={disabled || !nettoBet.isEditMode}
                               icon={faClock}
                               autoComplete={'off'}
                               name={'transitTime'}
                               id={'transitTime'}
                            //placeholder={t("quote.bet.transit-time")}
                               value={form.values.transitTime ?? ''}
                               onChange={form.handleChange}
                               onKeyDown={(event) => {

                                   switch (event.key) {
                                       case 'Backspace':
                                           return;
                                       case 'Delete':
                                           return;
                                   }

                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}/>
                        {nettoBet.transitTimeRequired !== null && nettoBet.transitTimeRequired !== '' &&
                            <div className={cl.required}>
                                {`${t("shared.labels.required")} ${nettoBet.transitTimeRequired}`}
                            </div>
                        }
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label required'}>
                            {t("quote.bet.till-date-time")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <div className={'picker'} style={{width: '100%'}}>
                                <ReactDatePicker disabled={disabled || !nettoBet.isEditMode}
                                                 size={'big'}
                                                 value={form.values.tillDate !== null && form.values.tillDate !== ''
                                                     ? moment(form.values.tillDate, 'DD.MM.YYYY').toDate()
                                                     : null}
                                                 onChange={(val) => {
                                                     if (val) {
                                                         form.setFieldValue('tillDate', moment(val).format('DD.MM.YYYY'));
                                                     } else {
                                                         form.setFieldValue('tillDate', null);
                                                     }
                                                 }}
                                />
                            </div>
                            <div style={{width: '40%'}}>
                                <ReactTimePicker size={'big'}
                                                 disabled={disabled || !nettoBet.isEditMode}
                                                 value={form.values.tillDateTime !== null && form.values.tillDateTime !== ''
                                                     ? moment(form.values.tillDateTime, 'HH:mm').toDate()
                                                     : null}
                                                 onChange={(val) => {
                                                     if (val) {
                                                         form.setFieldValue('tillDateTime',
                                                             moment(val).format('HH:mm'));
                                                     } else {
                                                         form.setFieldValue('tillDateTime', null);
                                                     }
                                                 }}/>
                            </div>
                        </div>

                        {nettoBet.rateValidUntilRequired !== null && nettoBet.rateValidUntilRequired !== '' &&
                            <div className={cl.required}>
                                {`${t("shared.labels.required")} ${nettoBet.rateValidUntilRequired}`}
                            </div>
                        }

                        {form.errors.tillDate &&
                            <div className={'error-label'}>
                                {form.errors.tillDate}
                            </div>
                        }
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.preavis")}
                        </label>
                        <Input disabled={disabled || !nettoBet.isEditMode}
                               icon={faClock}
                               autoComplete={'off'}
                               name={'preavis'}
                               id={'preavis'}
                            // placeholder={t("quote.bet.preavis")}
                               value={form.values.preavis ?? ''}
                               onChange={form.handleChange}
                               onKeyDown={(event) => {

                                   switch (event.key) {
                                       case 'Backspace':
                                           return;
                                       case 'Delete':
                                           return;
                                   }

                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}/>
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.loading-clearance-time")}
                        </label>
                        <Input disabled={disabled || !nettoBet.isEditMode}
                               icon={faClock}
                               autoComplete={'off'}
                               name={'loadingClearanceTime'}
                               id={'loadingClearanceTime'}
                            // placeholder={t("quote.bet.loading-clearance-time")}
                               value={form.values.loadingClearanceTime ?? ''}
                               onChange={form.handleChange}
                               onKeyDown={(event) => {

                                   switch (event.key) {
                                       case 'Backspace':
                                           return;
                                       case 'Delete':
                                           return;
                                   }

                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}/>
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`${cl.row} mgb-5`}>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.demurrage-price")}
                        </label>
                        <div className={cl.twoColumnRow}>
                            <div className={'numeric-text-box'} style={{width: '65%'}}>
                                <NumericTextBox disabled={disabled || !nettoBet.isEditMode}
                                    //  placeholder={t("quote.bet.demurrage-price")}
                                                spinners={false}
                                                name={'demurrage'}
                                                format={'n2'}
                                                value={form.values.demurrage && form.values.demurrage !== '' ? parseMoney(form.values.demurrage) : null}
                                                min={0}
                                                max={100000000}
                                                onChange={(ev) => {
                                                    if (ev.value === null || ev.value === 0) {
                                                        form.setFieldValue('demurrage', null);

                                                        return;
                                                    }

                                                    form.setFieldValue('demurrage', ev.value?.toString());
                                                }}
                                />
                            </div>
                            <div className={'drop-down-picker'} style={{width: '35%'}}>
                                <NettoBetDefaultCurrencySelector disabled={disabled || !nettoBet.isEditMode}
                                                                 currency={form.values.demurrageCurrencyCode}
                                                                 defaultCurrency={nettoBet.defaultCurrency}
                                                                 onChange={(currency) => {
                                                                     form.setFieldValue('demurrageCurrencyCode', currency);
                                                                 }}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-sm-2'}>
                        <label className={'view-label'}>
                            {t("quote.bet.unloading-clearance-time")}
                        </label>
                        <Input disabled={disabled || !nettoBet.isEditMode}
                               icon={faClock}
                               autoComplete={'off'}
                               name={'unloadingClearanceTime'}
                               id={'unloadingClearanceTime'}
                            // placeholder={t("quote.bet.unloading-clearance-time")}
                               value={form.values.unloadingClearanceTime ?? ''}
                               onChange={form.handleChange}
                               onKeyDown={(event) => {

                                   switch (event.key) {
                                       case 'Backspace':
                                           return;
                                       case 'Delete':
                                           return;
                                   }

                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}/>
                    </div>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasDemurrageNotes}
                              label={t("quote.bet.has-demurrage-notes")}
                              change={(val) => {
                                  form.setFieldValue('hasDemurrageNotes', val);

                                  if (!val && form.values.demurrageNotes !== null) {
                                      form.setFieldValue('demurrageNotes', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasDemurrageNotes &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                                  value={form.values.demurrageNotes ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('demurrageNotes', ev.target.value);
                                  }}/>
                    }
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasTransitCountries}
                              label={t("quote.bet.has-transit-countries")}
                              change={(val) => {
                                  form.setFieldValue('hasTransitCountries', val);

                                  if (!val && form.values.transitCountries !== null) {
                                      form.setFieldValue('transitCountries', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasTransitCountries &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                                  value={form.values.transitCountries ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('transitCountries', ev.target.value);
                                  }}/>
                    }
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasBorderCrossingPoints}
                              label={t("quote.bet.has-border-crossing-points")}
                              change={(val) => {
                                  form.setFieldValue('hasBorderCrossingPoints', val);

                                  if (!val && form.values.borderCrossingPoints !== null) {
                                      form.setFieldValue('borderCrossingPoints', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasBorderCrossingPoints &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                                  value={form.values.borderCrossingPoints ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('borderCrossingPoints', ev.target.value);
                                  }}/>
                    }
                </div>
            }

            {nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <label className={'view-label'}>
                        {t("quote.bet.comments")}
                    </label>
                    <Textarea disabled={disabled || !nettoBet.isEditMode || nettoBet.notAllowEditNotes}
                              style={textareaStyle}
                              autoComplete={'off'}
                              value={form.values.notes ?? ''}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('notes', ev.target.value);
                              }}/>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`} style={{marginTop: '10px'}}>
                    <label className={'view-label'}>
                        {t("quote.bet.additional-comments")}
                    </label>
                    <Textarea disabled={disabled || !nettoBet.isEditMode}
                              style={textareaStyle}
                              autoComplete={'off'}
                              value={form.values.additionalComments ?? ''}
                              onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                  form.setFieldValue('additionalComments', ev.target.value);
                              }}/>
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasPayableWeight}
                              label={t("quote.bet.has-payable-weight")}
                              change={(val) => {
                                  form.setFieldValue('hasPayableWeight', val);

                                  if (!val && form.values.payableWeight !== null) {
                                      form.setFieldValue('payableWeight', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasPayableWeight &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                                  value={form.values.payableWeight ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('payableWeight', ev.target.value);
                                  }}/>
                    }
                </div>
            }

            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasRateIncludes}
                              label={t("quote.bet.has-rate-includes")}
                              change={(val) => {
                                  form.setFieldValue('hasRateIncludes', val);

                                  if (!val && form.values.rateIncludes !== null) {
                                      form.setFieldValue('rateIncludes', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasRateIncludes &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                            // placeholder={t("quote.bet.client-has-access-to-this-message-warning")}
                                  value={form.values.rateIncludes ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('rateIncludes', ev.target.value);
                                  }}/>
                    }
                </div>
            }
            {!nettoBet.isTransporeon &&
                <div className={`view-row mgb-5`}>
                    <Checkbox checked={form.values.hasRateExcludes}
                              label={t("quote.bet.has-rate-excludes")}
                              change={(val) => {
                                  form.setFieldValue('hasRateExcludes', val);

                                  if (!val && form.values.rateExcludes !== null) {
                                      form.setFieldValue('rateExcludes', null);
                                  }
                              }}
                              disabled={disabled || !nettoBet.isEditMode}/>

                    {form.values.hasRateExcludes &&
                        <Textarea disabled={disabled || !nettoBet.isEditMode}
                                  style={textareaStyle}
                                  autoComplete={'off'}
                            // placeholder={t("quote.bet.client-has-access-to-this-message-warning")}
                                  value={form.values.rateExcludes ?? ''}
                                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
                                      form.setFieldValue('rateExcludes', ev.target.value);
                                  }}/>
                    }
                </div>
            }
        </form>
    );
};

export default QuoteNettoBetListItemForm;
